
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SprintResult } from '../../types';
import {
    getAllSprintsResults, postSprintResult, updateSprintResult,
} from '../../api';

export const getAllSprintResultsAction = createAsyncThunk<SprintResult[]>(
    'sprint/getAllSprintResultsAction',
    async () => await getAllSprintsResults() as SprintResult[],
);

export const postSprintResultAction = createAsyncThunk<SprintResult, SprintResult>(
    'sprint/postSprintResultAction',
    async (sprintResult) => {
        const succeed = await postSprintResult(sprintResult)
        return sprintResult;
    }
)

export const updateSprintResultAction = createAsyncThunk<SprintResult, SprintResult>(
    'sprint/updateSprintResultAction',
    async (sprintResult) => {
        const succeed = await updateSprintResult(sprintResult)
        return sprintResult;
    }
)