import { combineReducers, configureStore } from "@reduxjs/toolkit";
import driversReducer from "./slices/driverSlice";
import teamsReducer from "./slices/teamSlice";
import qualifyingBetsReducer from "./slices/qualifyingBetSlice";
import qualifyingResultsReducer from "./slices/qualifyingResultSlice";
import raceBetsReducer from "./slices/raceBetSlice";
import raceResultsReducer from "./slices/raceResultSlice";
import sprintBetsReducer from "./slices/sprintBetSlice";
import sprintResultsReducer from "./slices/sprintResultSlice";

const reducers = combineReducers({
  drivers: driversReducer,
  teams: teamsReducer,
  qualifyingBets: qualifyingBetsReducer,
  qualifyingResults: qualifyingResultsReducer,
  raceBets: raceBetsReducer,
  raceResults: raceResultsReducer,
  sprintBets: sprintBetsReducer,
  sprintResults: sprintResultsReducer,
});

export const store = configureStore({
  reducer: reducers,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
