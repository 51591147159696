import { createSlice } from '@reduxjs/toolkit';
import { IRaceBet } from "../../types"
import { getAllRaceBetsAction, postRaceBetAction, updateRaceBetAction } from '../actions';
import { showErrorToast, showSuccessToast } from '../../utils/toast';

interface IRaceBetData {
    raceBets: IRaceBet[]
    isLoading: boolean;
}

const RaceBetsSlice = createSlice({
    name: 'raceBet',
    initialState: {
        raceBets: [],
        isLoading: false
    } as IRaceBetData,
    reducers: {



    },
    extraReducers: builder => {
        builder
            // getAllRaceBetsAction
            .addCase(getAllRaceBetsAction.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.raceBets = payload
            })
            .addCase(getAllRaceBetsAction.pending, (state, { payload }) => {
                state.isLoading = true
            })
            // postRaceBetAction
            .addCase(postRaceBetAction.fulfilled, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = false;
                state.raceBets.push(payload)
                showSuccessToast('Bet saved!')
            })
            .addCase(postRaceBetAction.pending, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = true
            })
            .addCase(postRaceBetAction.rejected, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = false
                showErrorToast(payload as string)
            })
            // updateRaceBetAction
            .addCase(updateRaceBetAction.fulfilled, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = false;
                state.raceBets = state.raceBets.map(bet => bet._id === payload._id ? { ...payload } : { ...bet })
                showSuccessToast('Bet updated!')
            })
            .addCase(updateRaceBetAction.pending, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = true
            })
            .addCase(updateRaceBetAction.rejected, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = false
                showErrorToast(payload as string)
            })

    }
});

export default RaceBetsSlice.reducer;
