import { network } from "./"
import { logger } from '../utils';
import { RaceResult } from '../types';

const BASE = "race-result"

export const getRaceResult = async (raceId: string) => {
  try {
    const { data } = await network.get<RaceResult>(
      `${BASE}/${raceId}`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};


export const getAllRacesResults = async () => {
  try {
    const { data } = await network.get<RaceResult[]>(
      `${BASE}/all`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};

export const postRaceResult = async (race: RaceResult) => {
  try {
    const { data } = await network.post<{ success: boolean }>(
      `${BASE}`,
      race,
    );
    return data.success
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
}
export const updateRaceResult = async (race: RaceResult) => {
  try {
    const { data } = await network.put<{ success: boolean }>(
      `${BASE}/${race._id}`,
      race,
    );
    return data.success
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
}