import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  /* Reset default styles */
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  /* Set font-size to 16px and font-family to sans-serif */
  html {
    font-size: 62.5%;
    height: 100vh;
    width: 100vw;
  }
  body {
    font-family: sans-serif;
    font-size: 1.2rem;
    height: 100%;
    width: 100%;
  }

  #root {
    height: 100%;
    width: 100%;
  }

  /* Define colors */
  :root {
    --primary-color: #0077c9;
    --secondary-color: #3c3c3c;
    --tertiary-color: #f9a602;

    --han-blue-color: #3671C6;
    --scarlet-color: #F91536;
    --medium-aquamarine-color: #6CD3BF;
    --princeton-orange-color: #F58020;
    --cyan-cornflower-blue-color: #2293D1;
    --french-raspberry-color: #C92D4B;
    --illuminating-emerald-color: #358C75;
    --gray-x11-color: #B6BABD;
    --rackley-color: #5E8FAA;
    --picton-blue-color: #37BEDD;

  }
`;

export default GlobalStyles;





