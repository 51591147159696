const theme = {
  colors: {
    primary: "#1f2f66",
    secondary: "#60bb70",
    error: "#dc6d41",
    text: "#1f2f66",
    info: "#00a2da",
    warning: "#e29e15",
  },

  breakpoints: {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 0,
  },

  spacing: 8,

  typography: {
    fontSize: "16px",
    fontFamily: "'AlmoniNeue', Arial",
  },

  zIndex: {
    header: 100,
  },

  utils: {
    toPx: (num: any) => `${num}px`,

    hexToRgba: (hexCode:any, opacity:any) => {
      let hex = hexCode.replace("#", "");

      if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
      }

      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      return `rgba(${r},${g},${b},${opacity / 100})`;
    },
  },
};

export default theme;
