import styled from "styled-components";
/**
 * add px to number
 * @param {number} num
 * @returns string
 */
const toPx = (num: any) => `${num}px`;
// const Root = styled.div<{ error: string }>`

const Root = styled.div`
  position: relative;
  .btn {
    width: 0;
    height: 0;
    background: #fff;
    outline: 0;
    border: none;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  h2 {
    font-size: 1.6rem;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 1rem;
    color: ${(p) => p.theme.colors.primary["900"]};
  }

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: fixed;
    padding: 1rem 3rem;
    margin: auto;
    z-index: 999;
    width: 40%;
    height: fit-content;
    left: 0;
    top: 50%;
    right: 0;
    bottom: 50%;
    background: ${(p) => p.theme.colors.white};
    flex-wrap: wrap;

    @media (max-width: ${(p) => toPx(p.theme.breakpoints.xl)}) {
      padding: 1rem 2rem;
      width: 50%;
    }

    @media (max-width: ${(p) => toPx(p.theme.breakpoints.md)}) {
      width: 80%;
    }

    @media (max-width: ${(p) => toPx(p.theme.breakpoints.xs)}) {
      width: calc(100% - 32px);
      margin-right: 16px;
      margin-left: 16px;
    }
  }

  .xButton {
    background: none;
    border: 0 none;
    position: absolute;
    right: calc(2rem - 24px);
    top: 0.5rem;
    cursor: pointer;
    padding: 0;
    margin: 0;
    height: 24px;
    width: 24px;

    svg {
      pointer-events: none;
    }
  }
`;

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 6;
`;

export default {
  Root,
  Wrapper,
};
