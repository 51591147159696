
import { createAsyncThunk } from '@reduxjs/toolkit';
import { QualifyingResult } from '../../types';
import {
    getAllQualifyingsResults, postQualifyingResult, updateQualifyingResult,
} from '../../api';

export const getAllQualifyingResultsAction = createAsyncThunk<QualifyingResult[]>(
    'qualifying/getAllQualifyingResultsAction',
    async () => await getAllQualifyingsResults() as QualifyingResult[],
);

export const postQualifyingResultAction = createAsyncThunk<QualifyingResult, QualifyingResult>(
    'qualifying/postQualifyingResultAction',
    async (qualifyingResult) => {
        const succeed = await postQualifyingResult(qualifyingResult)
        return qualifyingResult;
    }
)

export const updateQualifyingResultAction = createAsyncThunk<QualifyingResult, QualifyingResult>(
    'qualifying/updateQualifyingResultAction',
    async (qualifyingResult) => {
        const succeed = await updateQualifyingResult(qualifyingResult)
        return qualifyingResult;
    }
)