
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IQualifyingBet } from '../../types';
import {
    getAllQualifyingsBets, postQualifyingBet, updateQualifyingBet,
} from '../../api';

export const getAllQualifyingBetsAction = createAsyncThunk<IQualifyingBet[]>(
    'qualifying/getAllQualifyingBetsAction',
    async () => await getAllQualifyingsBets() as IQualifyingBet[],
);

export const postQualifyingBetAction = createAsyncThunk<IQualifyingBet, IQualifyingBet>(
    'qualifying/postQualifyingBetAction',
    async (qualifyingBet, { rejectWithValue }) => {
        try {
            const response = await postQualifyingBet(qualifyingBet)
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data.message);
        }
    }
)

export const updateQualifyingBetAction = createAsyncThunk<IQualifyingBet, IQualifyingBet>(
    'qualifying/updateQualifyingBetAction',
    async (qualifyingBet, { rejectWithValue }) => {
        try {
            const response = await updateQualifyingBet(qualifyingBet)
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data.message)
        }
    }
)