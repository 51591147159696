import { createSlice } from '@reduxjs/toolkit';
import { Team } from "../../types"
import { getAllTeamsAction } from '../actions';

interface ITeamData {
    teams: Team[]
}

const teamsSlice = createSlice({
    name: 'teams',
    initialState: {} as ITeamData,
    reducers: {



    },
    extraReducers: builder => {
        builder.addCase(getAllTeamsAction.fulfilled, (state, action) => {
            state.teams = action.payload
        })

    }
});

export default teamsSlice.reducer;
