import { createSlice } from '@reduxjs/toolkit';
import { QualifyingResult } from "../../types"
import { getAllQualifyingResultsAction, postQualifyingResultAction, updateQualifyingResultAction } from '../actions';

interface IQualifyingResultData {
    qualifyingResults: QualifyingResult[];
    isLoading: boolean;
}

const qualifyingResultsSlice = createSlice({
    name: 'qualifyingResults',
    initialState: {
        qualifyingResults: [],
        isLoading: false
    } as IQualifyingResultData,
    reducers: {



    },
    extraReducers: builder => {
        // getAllQualifyingResultsAction
        builder.addCase(getAllQualifyingResultsAction.fulfilled, (state, { payload }) => {
            state.qualifyingResults = payload
            state.isLoading = false
        })
        builder.addCase(getAllQualifyingResultsAction.pending, (state, { payload }) => {
            state.isLoading = true
        })

        // postQualifyingResultAction
        builder.addCase(postQualifyingResultAction.fulfilled, (state, { payload }) => {
            // Add user to the state array
            state.isLoading = false;
            state.qualifyingResults.push(payload)
        })
        builder.addCase(postQualifyingResultAction.pending, (state, { payload }) => {
            // Add user to the state array
            state.isLoading = true
        })

        // updateQualifyingResultAction
        builder.addCase(updateQualifyingResultAction.fulfilled, (state, { payload }) => {
            // Add user to the state array
            state.isLoading = false;
            state.qualifyingResults = state.qualifyingResults.map(result => result._id === payload._id ? { ...payload } : { ...result })
        })
        builder.addCase(updateQualifyingResultAction.pending, (state, { payload }) => {
            // Add user to the state array
            state.isLoading = true
        })

    }
});

export default qualifyingResultsSlice.reducer;
