interface alpha2countriesInterface {
  [key: string]: string;
}

export const alpha2countries: alpha2countriesInterface = {
  bahrain: "bh",
  saudiarabia: "sa",
  australia: "au",
  japan: "jp",
  china: "cn",
  unitedstates: "us",
  italy: "it",
  monaco: "mc",
  canada: "ca",
  spain: "es",
  austria: "at",
  greatbritain: "gb",
  hungary: "hu",
  belgium: "be",
  netherlands: "nl",
  azerbaijan: "az",
  singapore: "sg",
  mexico: "mx",
  brazil: "br",
  qatar: "qa",
  abudhabi: "ae",
};
