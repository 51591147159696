import React from "react";
import styled from "styled-components";
import { Icon, IconNames, IconSizes } from "../generics/Icon";
import { useNavigateSearch } from "../hooks";
import { observer } from "mobx-react-lite";
import { useUser } from "../hooks/useUser";

const _Header = () => {
  const userState = useUser();

  const navigate = useNavigateSearch();

  const handleLogout = () => {
    userState.logout();
  };
  return (
    <StyledHeader>
      <StyledTab onClick={() => navigate("/")}>
        <Icon name={IconNames.F1_LOGO} size={IconSizes.M} />
      </StyledTab>
      <StyledTab onClick={() => navigate("/races")}>
        <Icon name={IconNames.FINISH} size={IconSizes.S} />
        Racing
      </StyledTab>
      {userState.isAdmin && (
        <StyledTab onClick={() => navigate("/register-admin")}>
          <Icon name={IconNames.REGISTRATION} size={IconSizes.S} />
          Admin
        </StyledTab>
      )}
      {userState.isAdmin && (
        <StyledTab onClick={() => navigate("/random-bets")}>
          <Icon name={IconNames.RED_FLAG} size={IconSizes.S} />
          Random
        </StyledTab>
      )}
      <StyledTab onClick={() => navigate("/profile")}>
        <Icon name={IconNames.PROFILE} size={IconSizes.S} />
        Profile
      </StyledTab>
      {userState.isSignedIn && (
        <StyledTab onClick={handleLogout}>
          <Icon name={IconNames.LOGOUT} size={IconSizes.S} />
          Logout
        </StyledTab>
      )}
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  margin: 0 !important;
  background-color: red;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const StyledTab = styled.div`
  text-align: center;
  font-size: 12px;
`;

export const Header = observer(_Header);
