import { isPast } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FieldContainer } from "../components/FieldContainer";
import { Button, ButtonTypes } from "../generics/Button";
import { IconNames } from "../generics/Icon";
import Ranks from "../generics/Ranks";
import { Spinner } from "../generics/Spinner";
import {
  useAppDispatch,
  useAppSelector,
  useNavigateSearch,
  useQuery,
  useRaces,
} from "../hooks";
import {
  getAllSprintBetsAction,
  getAllSprintResultsAction,
  postSprintBetAction,
  postSprintResultAction,
  updateSprintBetAction,
  updateSprintResultAction,
} from "../redux/actions";
import { IRace, ISprintBet, RaceTypes, SprintResult } from "../types";
import { driverRanksScore, driverRanksTotalScore } from "../utils/score";
import { GenericPage } from "./GenericPage";
import { observer } from "mobx-react-lite";
import { useUser } from "../hooks/useUser";

const initialState: ISprintBet | SprintResult = {
  raceId: "",
  driverRanks: [],
};

const _SprintBet: React.FC = () => {
  const userState = useUser();
  const raceState = useRaces();

  const navigate = useNavigateSearch();
  const { query, pathname } = useQuery();
  const dispatch = useAppDispatch();

  const results = useMemo(
    () => pathname?.split("/")?.[2] === "result",
    [pathname]
  );
  const raceId = useMemo(() => query.get("raceId") as string, [query]);

  const race = raceState.races.find((race) => race._id === raceId) as IRace;

  const editable = useMemo(
    () =>
      results ? userState.isAdmin : !isPast(new Date(race?.sprintDate as Date)),
    [race, results]
  );

  const [formData, setFormData] = useState<ISprintBet | SprintResult>(
    initialState
  );

  useEffect(() => {
    dispatch(getAllSprintBetsAction());
    dispatch(getAllSprintResultsAction());
  }, [dispatch]);

  const isLoading = useAppSelector((state) => {
    if (results) {
      return state.sprintResults?.isLoading as boolean;
    } else {
      return state.sprintBets?.isLoading as boolean;
    }
  });

  const sprintBet = useAppSelector(
    (state) =>
      state.sprintBets?.sprintBets?.find(
        (bet) => bet.raceId === raceId
      ) as ISprintBet
  );
  const sprintResult = useAppSelector(
    (state) =>
      state.sprintResults?.sprintResults?.find(
        (result) => result.raceId === raceId
      ) as SprintResult
  );

  useEffect(() => {
    setFormData(
      (results ? sprintResult : sprintBet) ?? {
        ...initialState,
        raceId,
      }
    );
  }, [sprintResult, sprintBet, raceId, results]);

  const handleChange = ({ name, value }: { name: string; value: any }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    if (results) {
      dispatch(
        formData._id
          ? updateSprintResultAction(formData)
          : postSprintResultAction(formData)
      );
    } else {
      dispatch(
        formData._id
          ? updateSprintBetAction(formData)
          : postSprintBetAction(formData)
      );
    }
  };

  const driverRanksScoreTotal = useMemo(
    () =>
      driverRanksTotalScore(
        driverRanksScore(
          sprintBet?.driverRanks ?? [],
          sprintResult?.driverRanks ?? []
        ) ?? []
      ),
    [sprintBet, sprintResult]
  );

  const completed = useMemo(
    () => formData?.driverRanks?.length === 20,
    [formData]
  );

  const handleBack = () => {
    navigate("/race", { raceId });
  };

  return (
    <GenericPage
      title="sprint"
      subtitle={results ? "results" : ""}
      icon={!!formData._id ? IconNames.SUCCESS : undefined}
    >
      <Spinner loading={isLoading}>
        <RaceContainer>
          <FieldContainer
            icon={IconNames.RANKING}
            completed={formData?.driverRanks?.length === 20}
            score={results ? undefined : driverRanksScoreTotal}
          >
            <Ranks
              value={formData.driverRanks}
              editable={editable}
              raceId={raceId}
              type={RaceTypes.SPRINT}
              length={20}
              onChange={(value) => handleChange({ name: "driverRanks", value })}
            ></Ranks>
          </FieldContainer>
          {editable && (
            <Button
              type={ButtonTypes.PRIMARY}
              onClick={handleSubmit}
              text="Submit"
              disabled={isLoading || !completed}
            />
          )}
          <Button
            type={ButtonTypes.SECONDARY}
            onClick={handleBack}
            text="Back"
            disabled={isLoading}
          />
        </RaceContainer>
      </Spinner>
    </GenericPage>
  );
};
export const SprintBet = observer(_SprintBet);

const RaceContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`;
