import { network } from "./"
import { logger } from '../utils';
import { ISprintBet } from '../types';
import { AxiosResponse } from "axios";

const BASE = "sprint-bet"

export const getSprintBet = async (id: string) => {
  try {
    const { data } = await network.get<ISprintBet>(
      `${BASE}/${id}`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};


export const getAllSprintsBets = async () => {
  try {
    const { data } = await network.get<ISprintBet[]>(
      `${BASE}/all`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};

export const postSprintBet = async (sprint: ISprintBet): Promise<AxiosResponse<ISprintBet>> =>
  await network.post(
    `${BASE}`,
    sprint,
  );

export const updateSprintBet = async (sprint: ISprintBet): Promise<AxiosResponse<ISprintBet>> =>
  await network.put(
    `${BASE}/${sprint._id}`,
    sprint,
  );