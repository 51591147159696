import { network } from "./";
import { logger } from "../utils";
import { IRaceBet, RaceTypes } from "../types";
import { AxiosResponse } from "axios";

const BASE = "random-bet";

export const createRandomBet = async (
  uId: string,
  raceType: RaceTypes,
  raceId: string
): Promise<AxiosResponse<IRaceBet>> =>
  await network.post(`${BASE}`, { uId, raceType, raceId });
