import { TeamEnum } from "../types";

export const getColorTeam = (team: TeamEnum) => {
    switch (team) {
        case TeamEnum.RED_BULL:
            return "#3671C6";
        case TeamEnum.ASTON_MARTIN:
            return "#358C75";
        case TeamEnum.MERCEDES:
            return "#6CD3BF";
        case TeamEnum.FERRARI:
            return "#F91536";
        case TeamEnum.ALFA_ROMEO:
            return "#C92D4B";
        case TeamEnum.ALPINE:
            return "#2293D1";
        case TeamEnum.WILLIAMS:
            return "#37BEDD";
        case TeamEnum.ALPHATAURI:
            return "#5E8FAA";
        case TeamEnum.HAAS:
            return "#B6BABD";
        case TeamEnum.MCLAREN:
            return "#F58020";
        default:
            return "#FFFFFF";
    }
}