import { network } from "./"
import { logger } from '../utils';
import { SprintResult } from '../types';

const BASE = "sprint-result"

export const getSprintResult = async (raceId: string) => {
  try {
    const { data } = await network.get<SprintResult>(
      `${BASE}/${raceId}`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};


export const getAllSprintsResults = async () => {
  try {
    const { data } = await network.get<SprintResult[]>(
      `${BASE}/all`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};

export const postSprintResult = async (sprint: SprintResult) => {
  try {
    const { data } = await network.post<{ success: boolean }>(
      `${BASE}`,
      sprint,
    );
    return data.success
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
}

export const updateSprintResult = async (sprint: SprintResult) => {
  try {
    const { data } = await network.put<{ success: boolean }>(
      `${BASE}/${sprint._id}`,
      sprint,
    );
    return data.success
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
}