import { network } from "./"
import { logger } from '../utils';
import { QualifyingResult } from '../types';

const BASE = "qualifying-result"

export const getQualifyingResult = async (raceId: string) => {
  try {
    const { data } = await network.get<QualifyingResult>(
      `${BASE}/${raceId}`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};


export const getAllQualifyingsResults = async () => {
  try {
    const { data } = await network.get<QualifyingResult[]>(
      `${BASE}/all`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};

export const postQualifyingResult = async (race: QualifyingResult) => {
  try {
    const { data } = await network.post<{ success: boolean }>(
      `${BASE}`,
      race,
    );
    return data.success
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
}

export const updateQualifyingResult = async (qualifying: QualifyingResult) => {
  try {
    const { data } = await network.put<{ success: boolean }>(
      `${BASE}/${qualifying._id}`,
      qualifying,
    );
    return data.success
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
}