
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISprintBet } from '../../types';
import {
    getAllSprintsBets, postSprintBet, updateSprintBet,
} from '../../api';

export const getAllSprintBetsAction = createAsyncThunk<ISprintBet[]>(
    'sprint/getAllSprintBetsAction',
    async () => await getAllSprintsBets() as ISprintBet[],
);

export const postSprintBetAction = createAsyncThunk<ISprintBet, ISprintBet>(
    'sprint/postSprintBetAction',
    async (sprintBet, { rejectWithValue }) => {
        try {
            const response = await postSprintBet(sprintBet)
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data.message);
        }

    }
)

export const updateSprintBetAction = createAsyncThunk<ISprintBet, ISprintBet>(
    'sprint/updateSprintBetAction',
    async (sprintBet, { rejectWithValue }) => {
        try {
            const response = await updateSprintBet(sprintBet)
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data.message);
        }
    }
)