import { network } from "./";
import { logger } from "../utils";
import { IRace, RaceStatus } from "../types";
import { AxiosResponse } from "axios";

const BASE = "race";

export const getAllRaces = async () => {
  try {
    const { data } = await network.get<IRace[]>(BASE);
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return [];
  }
};

export const postRace = async (race: Partial<IRace>) =>
  await network.post<IRace>(BASE, race);

export const updateRaceStatus = async (
  raceId: string,
  status: RaceStatus
): Promise<AxiosResponse<{ success: boolean }>> =>
  await network.put(`${BASE}/${raceId}`, { status });

export const updateRace = async (
  raceId: string,
  fieldsToUpdate: Partial<IRace>
): Promise<AxiosResponse<{ success: boolean }>> =>
  await network.put(`${BASE}/${raceId}`, fieldsToUpdate);
