import React, { useRef } from "react";
import E from "./Modal.style";

interface Props {
  openModal: any;
  closeModal: (e: any) => void;
  children: JSX.Element[] | JSX.Element;
  hiddeCloseButton: any;
}
const Modal: React.FC<Props> = ({
  closeModal,
  openModal,
  children,
  hiddeCloseButton,
}) => {
  const handleCloseModal = (e: any) => {
    if (!hiddeCloseButton) {
      if (e.target.id === "modalWrapper" || e.target.id === "xButton") {
        closeModal(e);
      }
    }
  };

  return openModal && (
    <E.Root onClick={(e) => handleCloseModal(e)}>
      <E.Wrapper id="modalWrapper"/>
      <div className="modal" id="modal">
        {!hiddeCloseButton && (
          <button
            type="button"
            className="xButton"
            id="xButton"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M13.0607 12L15.8891 14.8285L14.8285 15.8891L12 13.0607L9.1716 15.8891L8.11094 14.8285L10.9394 12L8.11094 9.1716L9.1716 8.11094L12 10.9394L14.8285 8.11094L15.8891 9.1716L13.0607 12Z"
              />
              <path
                fill="currentColor"
                d="M2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75Z"
              />
            </svg>
          </button>
        )}
        {children}
      </div>
    </E.Root>
  );
};

export default Modal;
