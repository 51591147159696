
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Team } from '../../types';
import {
    getAllTeams,
} from '../../api';

export const getAllTeamsAction = createAsyncThunk<Team[]>(
    'teams/getAllTeamsAction',
    async () => await getAllTeams(),
);