import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "../generics/Modal";
import DriverRanks from "../components/DriverRanks";
import DriverRanksResult from "../components/DriverRanksResult";
import { RaceTypes } from "../types";
interface Props {
  open: boolean;
  length: number;
  editable: boolean;
  raceId: string;
  type: RaceTypes;
  ranks: { driverId: string; rank: number }[];
  selected: (e: any) => void;
}

const RanksModal: React.FC<Props> = ({
  open,
  ranks,
  editable,
  raceId,
  type,
  length,
  selected,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [localRanks, setLocalRanks] = useState<string[]>([]);
  
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = (event: any) => {
    event.stopPropagation();
    selected({ completed, ranks: localRanks });
  };

  const handleSelected = ({
    completed,
    ranks,
  }: {
    completed: boolean;
    ranks: string[];
  }) => {
    setCompleted(completed);
    setLocalRanks(ranks);
  };

  return (
    <Modal
      openModal={isOpen}
      hiddeCloseButton={false}
      closeModal={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledModal completed={completed} border={editable}>
        {editable ? (
          <DriverRanks
            ranks={ranks}
            length={length}
            selected={handleSelected}
          />
        ) : (
          <DriverRanksResult raceId={raceId} type={type} ranks={ranks}/>
        )}
      </StyledModal>
    </Modal>
  );
};

export default RanksModal;

const StyledModal = styled.div<{ border: boolean; completed: boolean }>`
  width: 100%;
  /* height: 100%; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) =>
    props.border ? `10px solid ${props.completed ? "green" : "red"}` : "none"};
  border-radius: 5px;
  box-sizing: border-box;

  &:focus {
    outline: "none";
  }
`;
