import { createSlice } from '@reduxjs/toolkit';
import { ISprintBet } from "../../types"
import { getAllSprintBetsAction, postSprintBetAction, updateSprintBetAction } from '../actions';
import { showErrorToast, showSuccessToast } from '../../utils/toast';

interface ISprintBetData {
    sprintBets: ISprintBet[]
    isLoading: boolean;
}

const SprintBetsSlice = createSlice({
    name: 'SprintBet',
    initialState: {
        sprintBets: [],
        isLoading: false
    } as ISprintBetData,
    reducers: {



    },
    extraReducers: builder => {
        builder
            // getAllSprintBetsAction
            .addCase(getAllSprintBetsAction.fulfilled, (state, { payload }) => {
                state.sprintBets = payload
                state.isLoading = false;
            })
            .addCase(getAllSprintBetsAction.pending, (state, { payload }) => {
                state.isLoading = true;
            })
            // postSprintBetAction
            .addCase(postSprintBetAction.fulfilled, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = false;
                state.sprintBets.push(payload)
                showSuccessToast('Bet saved!')
            })
            .addCase(postSprintBetAction.pending, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = true
            })
            .addCase(postSprintBetAction.rejected, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = false
                showErrorToast(payload as string)
            })
            // updateSprintBetAction
            .addCase(updateSprintBetAction.fulfilled, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = false
                state.sprintBets = state.sprintBets.map(bet => bet._id === payload._id ? { ...payload } : { ...bet })
                showSuccessToast('Bet updated!')
            })
            .addCase(updateSprintBetAction.pending, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = true
            })
            .addCase(updateSprintBetAction.rejected, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = false
                showErrorToast(payload as string)
            })

    }
});

export default SprintBetsSlice.reducer;
