export enum TeamEnum {
    RED_BULL = "red bull racing",
    ASTON_MARTIN = "aston martin",
    MERCEDES = "mercedes",
    FERRARI = "ferrari",
    ALFA_ROMEO = "alfa romeo",
    ALPINE = "alpine",
    WILLIAMS = "williams",
    ALPHATAURI = "alphatauri",
    HAAS = "haas f1 team",
    MCLAREN = "mclaren",
}

export interface Team {
    // properties
    _id: string;
    name: TeamEnum;
    createdAt: Date;
    updatedAt: Date;
}
