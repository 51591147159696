import {
  Table,
  TableActionCell,
  TableActionCellPrimaryActionVisibility,
} from "@wix/design-system";
import React from "react";
import { IRace, User } from "../types";

export const RandomBet: React.FC<{
  missingBets: IRace[];
  createBet: (id: string) => void;
}> = ({ missingBets, createBet }) => {
  const columns = [
    { title: "Race", render: (row: IRace) => row.country },
    {
      title: "",
      render: (row: IRace) => (
        <TableActionCell
          size="small"
          primaryAction={{
            text: "Edit",
            onClick: () => {
              createBet(row._id);
            },
            visibility: "always" as TableActionCellPrimaryActionVisibility,
          }}
          moreActionsTooltipText="More actions"
        />
      ),
      stickyActionCell: true,
      width: 30,
    },
  ];

  return (
    <Table columns={columns} data={missingBets}>
      <Table.Content />
    </Table>
  );
};
