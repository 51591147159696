import React from "react";
import { Score } from "../components/DriverRanksResult";
import { Container, StatusContainer } from "../components/FieldContainer";
import { ScoreStatus } from "../utils/score";
import { Icon, IconNames } from "./Icon";
import Label from "./Label";
import E from "./Switch.style";

interface ISwitchProps {
  value: boolean;
  editable: boolean;
  onChange: (e: any) => void;
}
const Switch: React.FC<ISwitchProps> = ({
  value,
  editable,
  onChange,
}) => {
  const handleChange = ({ target }: { target: any }) => {
    if (!editable) return;
    onChange(target.checked);
  };
  return (
    <E.Root>
      <label className="switch">
        <input
          type="checkbox"
          //   id={id}
          onChange={(e) => handleChange(e)}
          // value={value}
          checked={!!value && value}
          //   disabled={disabled}
          //   ref={ref}
          //   {...rest}
        />
        <div className="slider" />
      </label>
    </E.Root>
  );
};
export default Switch;
