
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IRaceBet } from '../../types';
import {
    getAllRacesBets, postRaceBet, updateRaceBet,
} from '../../api';

export const getAllRaceBetsAction = createAsyncThunk<IRaceBet[]>(
    'raceBet/getAllRaceBetsAction',
    async () => await getAllRacesBets() as IRaceBet[],
);

export const postRaceBetAction = createAsyncThunk<IRaceBet, IRaceBet>(
    'raceBet/postRaceBetAction',
    async (RaceBet, { rejectWithValue }) => {
        try {
            const response = await postRaceBet(RaceBet)
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data.message);
        }
    }
)

export const updateRaceBetAction = createAsyncThunk<IRaceBet, IRaceBet>(
    'raceBet/updateRaceBetAction',
    async (RaceBet, { rejectWithValue }) => {
        try {
            const response = await updateRaceBet(RaceBet)
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data.message);
        }
    }
)