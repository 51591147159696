import { createSlice } from '@reduxjs/toolkit';
import {Driver} from "../../types"
import { getAllDriversAction } from '../actions/driver';

 interface IDriverData {    
    drivers :Driver[]
}

const driversSlice = createSlice({
    name: 'drivers',
    initialState: {} as IDriverData ,
    reducers: {

     
        
    },
    extraReducers : builder=>{
        builder.addCase(getAllDriversAction.fulfilled,(state, action)=>{
            state.drivers = action.payload
        })

    }
});

export default driversSlice.reducer;
