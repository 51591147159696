export enum RaceStatus {
  PENDING = "pending",
  ACTIVE = "active",
  COMPLETED = "completed",
  CANCELED = "canceled",
}
export interface IRace {
  // properties
  _id: string;
  round: number;
  name: string;
  country: string;
  qualifyingDate: Date;
  sprintDate: Date;
  raceDate: Date;
  createdAt: Date;
  updatedAt: Date;
  status: RaceStatus;
}

export enum RaceTypes {
  QUALIFYING = "qualifying",
  SPRINT = "sprint",
  RACE = "race",
}

export type ViewTypes = "upcoming" | "past";
