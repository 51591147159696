import {
  Cell,
  Layout,
  FormField,
  Input,
  Card,
  ToggleSwitch,
  Button,
  Loader,
  Modal,
} from "@wix/design-system";
import { useState } from "react";
import { Add } from "@wix/wix-ui-icons-common";
import { convertDateTimeToDateObject } from "../utils";
import { IRace } from "../types";
import { observer } from "mobx-react-lite";
import { RacesState } from "../state";

interface CreateRaceModalProps {
  state: RacesState;
}

const _CreateRaceModal: React.FC<CreateRaceModalProps> = ({ state }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [newRace, setNewRace] = useState<any>({});

  const handleClose = () => {
    state.openCreateRaceModal = false;
  };

  const updateRace = (key: string, value: any) => {
    setNewRace({ ...newRace, [key]: value });
  };

  const submitRace = () => {
    console.log(newRace);
    const raceToCreate: Partial<IRace> = {
      round: Number(newRace.round),
      name: newRace.name,
      country: newRace.country,
      qualifyingDate: convertDateTimeToDateObject(
        newRace.qualifyingDate,
        newRace.qualifyingTime
      ) as Date,
      raceDate: convertDateTimeToDateObject(
        newRace.raceDate,
        newRace.raceTime
      ) as Date,
      ...(isChecked
        ? {
            sprintDate: convertDateTimeToDateObject(
              newRace.sprintDate,
              newRace.sprintTime
            ),
          }
        : {}),
    };
    state.createRace(raceToCreate);
  };
  return (
    <Modal
      isOpen={state.openCreateRaceModal}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      shouldDisplayCloseButton
      screen="desktop"
    >
      <Card>
        <Card.Header
          title="Race info"
          suffix={
            <Button
              size="small"
              prefixIcon={state.isLoading ? <Loader size="tiny" /> : <Add />}
              onClick={submitRace}
            >
              {state.isLoading ? "" : "Create"}
            </Button>
          }
        />
        <Card.Divider />
        <Card.Content>
          <Layout>
            <Cell>
              <FormField label="Round" required>
                <Input
                  type="number"
                  onChange={(e) => updateRace("round", e.target.value)}
                />
              </FormField>
            </Cell>
            <Cell>
              <FormField label="Name" required>
                <Input onChange={(e) => updateRace("name", e.target.value)} />
              </FormField>
            </Cell>
            <Cell>
              <FormField label="Country" required>
                <Input
                  onChange={(e) => updateRace("country", e.target.value)}
                />
              </FormField>
            </Cell>
            <Cell span={6}>
              <FormField label="Qualifying Date" required>
                <Input
                  type="date"
                  onChange={(e) => updateRace("qualifyingDate", e.target.value)}
                />
              </FormField>
            </Cell>
            <Cell span={6}>
              <FormField label="Time" required>
                <Input
                  type="time"
                  onChange={(e) => updateRace("qualifyingTime", e.target.value)}
                />
              </FormField>
            </Cell>
            <Cell span={6}>
              <FormField label="Race Date" required>
                <Input
                  type="date"
                  onChange={(e) => updateRace("raceDate", e.target.value)}
                />
              </FormField>
            </Cell>
            <Cell span={6}>
              <FormField label="Time" required>
                <Input
                  type="time"
                  onChange={(e) => updateRace("raceTime", e.target.value)}
                />
              </FormField>
            </Cell>
            <Cell>
              <FormField
                labelPlacement="left"
                label="Do we have sprint this weekend?"
              >
                <ToggleSwitch
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
              </FormField>
            </Cell>
            {isChecked && (
              <>
                <Cell span={6}>
                  <FormField label="Sprint Date" required>
                    <Input
                      type="date"
                      onChange={(e) => updateRace("sprintDate", e.target.value)}
                    />
                  </FormField>
                </Cell>
                <Cell span={6}>
                  <FormField label="Time" required>
                    <Input
                      type="time"
                      onChange={(e) => updateRace("sprintTime", e.target.value)}
                    />
                  </FormField>
                </Cell>
              </>
            )}
          </Layout>
        </Card.Content>
      </Card>
    </Modal>
  );
};

export const CreateRaceModal = observer(_CreateRaceModal);
