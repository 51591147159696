// TODO check responses and tokens
import axios from "axios";
import Cookies from "js-cookie";
import { getAccessToken } from "./auth";
export const ACCESS_TOKEN_KEY = "ACCESS_TOKEN_KEY"

export const network = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
 
});


network.interceptors.request.use(async (config) => {
  config.headers!.authorization = `Bearer ${Cookies.get(ACCESS_TOKEN_KEY)}`;
  return config;
});

network.interceptors.response.use(
  (response) => response,
  async (error) => {
  const status = error?.response?.status;
  const originalRequest = error.config;
  if (status === 400) {
    try {
      await getAccessToken()
      const data = await network(originalRequest);
      return data;
    } catch (e) {
      //  @ts-ignore    
      logger.error(e.message);
      // throw e;
    }
  } else {
    throw error;
  }
  }
);

