import { isPast } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FieldContainer } from "../components/FieldContainer";
import { Button, ButtonTypes } from "../generics/Button";
import { IconNames } from "../generics/Icon";
import Input from "../generics/Input";
import Ranks from "../generics/Ranks";
import Select from "../generics/Select";
import { Spinner } from "../generics/Spinner";
import Switch from "../generics/Switch";
import {
  useAppDispatch,
  useAppSelector,
  useNavigateSearch,
  useQuery,
  useRaces,
} from "../hooks";
import {
  getAllRaceBetsAction,
  getAllRaceResultsAction,
  postRaceBetAction,
  postRaceResultAction,
  updateRaceBetAction,
  updateRaceResultAction,
} from "../redux/actions";
import { IRace, IRaceBet, RaceResult, RaceTypes } from "../types";
import {
  driverRanksScore,
  driverRanksTotalScore,
  getRaceScore,
} from "../utils/score";
import { GenericPage } from "./GenericPage";
import { observer } from "mobx-react-lite";
import { useUser } from "../hooks/useUser";

const initialState: IRaceBet = {
  raceId: "",
  driverRanks: [],
  fastestLap: "",
  mvp: "",
  totalFinished: 20,
  redFlag: false,
  safetyCar: false,
  vSafetyCar: false,
  firstToPit: "",
  firstToPenalty: "",
};

const _RaceBet: React.FC = () => {
  const userState = useUser();
  const raceState = useRaces();

  const navigate = useNavigateSearch();
  const { query, pathname } = useQuery();
  const dispatch = useAppDispatch();

  const results = useMemo(
    () => pathname?.split("/")?.[2] === "result",
    [pathname]
  );
  const raceId = useMemo(() => query.get("raceId") as string, [query]);

  const race = raceState.races.find((race) => race._id === raceId) as IRace;

  const editable = useMemo(
    () =>
      results ? userState.isAdmin : !isPast(new Date(race?.raceDate as Date)),
    [race, results]
  );

  const [formData, setFormData] = useState<IRaceBet | RaceResult>(initialState);

  useEffect(() => {
    dispatch(getAllRaceBetsAction());
    dispatch(getAllRaceResultsAction());
  }, [dispatch]);

  const isLoading = useAppSelector((state) => {
    if (results) {
      return state.raceResults?.isLoading as boolean;
    } else {
      return state.raceBets?.isLoading as boolean;
    }
  });
  const raceBet = useAppSelector(
    (state) =>
      state.raceBets?.raceBets?.find((bet) => bet.raceId === raceId) as IRaceBet
  );
  const raceResult = useAppSelector(
    (state) =>
      state.raceResults?.raceResults?.find(
        (result) => result.raceId === raceId
      ) as RaceResult
  );

  const raceScore = useMemo(
    () => getRaceScore(raceBet ?? [], raceResult ?? []),
    [raceBet, raceResult]
  );

  useEffect(() => {
    setFormData(
      (results ? raceResult : raceBet) ?? { ...initialState, raceId }
    );
  }, [raceResult, raceBet, raceId, results]);

  const handleChange = ({ name, value }: { name: string; value: any }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    if (results) {
      dispatch(
        formData._id
          ? updateRaceResultAction(formData)
          : postRaceResultAction(formData)
      );
    } else {
      dispatch(
        formData._id
          ? updateRaceBetAction(formData)
          : postRaceBetAction(formData)
      );
    }
  };

  const driverRanksScoreTotal = useMemo(
    () =>
      driverRanksTotalScore(
        driverRanksScore(
          raceBet?.driverRanks ?? [],
          raceResult?.driverRanks ?? []
        ) ?? []
      ),
    [raceBet, raceResult]
  );

  const completed = useMemo(
    () =>
      formData?.driverRanks?.length === 20 &&
      !!formData?.mvp &&
      !!formData?.fastestLap &&
      !!formData?.firstToPit &&
      !!formData?.firstToPenalty,
    [formData]
  );

  const handleBack = () => {
    navigate("/race", { raceId });
  };

  return (
    <GenericPage
      title="race"
      subtitle={results ? "results" : ""}
      icon={!!formData._id ? IconNames.SUCCESS : undefined}
    >
      <Spinner loading={isLoading}>
        <RaceContainer>
          <FieldContainer
            icon={IconNames.RANKING}
            completed={formData?.driverRanks?.length === 20}
            score={results ? undefined : driverRanksScoreTotal}
          >
            <Ranks
              length={20}
              value={formData.driverRanks}
              editable={editable}
              raceId={raceId}
              type={RaceTypes.RACE}
              onChange={(value) => handleChange({ name: "driverRanks", value })}
            ></Ranks>
          </FieldContainer>
          <FieldContainer
            icon={IconNames.MVP}
            score={results ? undefined : raceScore?.["mvp"]}
            completed={!!formData?.mvp}
          >
            <Select
              editable={editable}
              value={formData.mvp}
              onChange={(value) => handleChange({ name: "mvp", value })}
            ></Select>
          </FieldContainer>
          <FieldContainer
            icon={IconNames.SPEED}
            score={results ? undefined : raceScore?.["fastestLap"]}
            completed={!!formData?.fastestLap}
          >
            <Select
              editable={editable}
              value={formData?.fastestLap}
              onChange={(value) => handleChange({ name: "fastestLap", value })}
            ></Select>
          </FieldContainer>
          <FieldContainer
            icon={IconNames.FINISH}
            score={results ? undefined : raceScore?.["totalFinished"]}
            completed
          >
            <Input
              value={formData.totalFinished}
              editable={editable}
              onChange={(value) =>
                handleChange({ name: "totalFinished", value })
              }
            ></Input>
          </FieldContainer>
          <FieldContainer
            icon={IconNames.RED_FLAG}
            score={results ? undefined : raceScore?.["redFlag"]}
            completed
          >
            <Switch
              value={formData.redFlag}
              editable={editable}
              onChange={(value) => handleChange({ name: "redFlag", value })}
            ></Switch>
          </FieldContainer>
          <FieldContainer
            icon={IconNames.SAFETY_CAR}
            score={results ? undefined : raceScore?.["safetyCar"]}
            completed
          >
            <Switch
              value={!!formData.safetyCar}
              editable={editable}
              onChange={(value) => handleChange({ name: "safetyCar", value })}
            ></Switch>
          </FieldContainer>
          <FieldContainer
            icon={IconNames.V_SAFETY_CAR}
            score={results ? undefined : raceScore?.["vSafetyCar"]}
            completed
          >
            <Switch
              value={!!formData.vSafetyCar}
              editable={editable}
              onChange={(value) => handleChange({ name: "vSafetyCar", value })}
            ></Switch>
          </FieldContainer>
          <FieldContainer
            icon={IconNames.PIT}
            score={results ? undefined : raceScore?.["firstToPit"]}
            completed={!!formData?.firstToPit}
          >
            <Select
              editable={editable}
              value={formData?.firstToPit}
              onChange={(value) => handleChange({ name: "firstToPit", value })}
            ></Select>
          </FieldContainer>
          <FieldContainer
            icon={IconNames.PENALTY}
            score={results ? undefined : raceScore?.["firstToPenalty"]}
            completed={!!formData?.firstToPenalty}
          >
            <Select
              editable={editable}
              value={formData.firstToPenalty}
              onChange={(value) =>
                handleChange({ name: "firstToPenalty", value })
              }
            ></Select>
          </FieldContainer>
          {editable && (
            <Button
              type={ButtonTypes.PRIMARY}
              onClick={handleSubmit}
              text="Submit"
              disabled={isLoading || !completed}
            ></Button>
          )}
          <Button
            type={ButtonTypes.SECONDARY}
            onClick={handleBack}
            text="Back"
            disabled={isLoading}
          ></Button>
        </RaceContainer>
      </Spinner>
    </GenericPage>
  );
};

export const RaceBet = observer(_RaceBet);

const RaceContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`;
