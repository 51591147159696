import React, { PropsWithChildren } from "react";
import Lottie from "lottie-react";
import styled from "styled-components";
import spinner from "./../spinner.json";

interface ISpinner {
  loading: boolean;
}
export const Spinner: React.FC<PropsWithChildren<ISpinner>> = ({
  loading,
  children,
}) => {
  return (
    <>
      {loading ? (
        <Container>
          <StyledLottie animationData={spinner} loop={true} />
        </Container>
      ) : (
        children
      )}
    </>
  );
};

const Container = styled.div`
  flex: auto;
  position: relative;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  width: 100%;
  height: 100%;
  user-select: none;
`;

const StyledLottie = styled(Lottie)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  height: 54px;
  aspect-ratio: 1/1;
`;
