import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { Icon, IconNames } from "../generics/Icon";
import { ScoreStatus } from "../utils/score";
import { Score } from "./DriverRanksResult";

interface IFieldContainerProps {
  icon?: IconNames;
  score?: { status: ScoreStatus; value: number };
  completed?: boolean;
}
export const FieldContainer: React.FC<
  PropsWithChildren<IFieldContainerProps>
> = ({ icon, score, completed, children }) => {
  return (
    <Container>
      {icon && <Icon name={icon} />}
      <StatusContainer completed={!!completed}>{children}</StatusContainer>
      {score && (
        <Score status={score?.status as ScoreStatus}>{score?.value}</Score>
      )}
    </Container>
  );
};

export const Container = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 10px;
`;

export const StatusContainer = styled.div<{ completed: boolean }>`
  padding: 0.5rem;
  flex: 2;
  border-radius: 5px;
  background-color: ${(props) => (props.completed ? "#008854" : "#C8102E")};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: ${(props) => (props.completed ? 1 : 0.5)}; */
  letter-spacing: 0.25rem;
  font-weight: ${(props) => (props.completed ? 600 : 400)};
`;
