import styled from "styled-components";

const Root = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
  justify-content: center;

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    flex: 0 0 auto;
    margin-top: 2px;

    & input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .label {
    margin: 0;
    padding-right: ${(p) => p.theme.utils.toPx(p.theme.spacing)};
    user-select: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ced4da;
    transition: 0.3s;
    border-radius: 34px;

    &:before {
      content: "";
      position: absolute;
      height: 14px;
      width: 14px;
      right: 4px;
      bottom: 3px;
      background-color: #fff;
      transition: 0.2s;
      border-radius: 50%;
    }
  }

  & input {
    &:checked + .slider {
      background-color: ${(p) => p.theme.colors.primary};

      &:before {
        transform: translateX(-18px);
      }
    }

    &:focus + .slider {
      border-color: ${(p) => p.theme.colors.primary};
      box-shadow: 0 0 0 4px ${(p) => "rgba(32, 47, 101, 15%)"};
    }

    &:disabled + .slider {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
`;

// eslint-disable-next-line
export default {
  Root,
};
