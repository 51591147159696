import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Spinner } from "../generics/Spinner";
import { Profile } from "./Profile";
import ScoreboardPage from "./ScoreboardPage";
import { RacesPage } from "./RacesPage";
import { RacePage } from "./RacePage";
import { useUser } from "../hooks/useUser";
import { observer } from "mobx-react-lite";
import {
  Login,
  QualifyingBet,
  RaceBet,
  RandomBetsPage,
  RegisterAdmin,
  SprintBet,
} from "../pages";
import { useAppDispatch } from "../hooks";
import { getAllDriversAction, getAllTeamsAction } from "../redux/actions";

const _Navigator: React.FC = () => {
  const userState = useUser();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllDriversAction());
    dispatch(getAllTeamsAction());
  }, [dispatch]);

  if (userState.loadingAuth) {
    return <Spinner loading />;
  }
  return (
    <Router>
      <Routes>
        {!userState.isSignedIn ? (
          <Route path="*" element={<Login />} />
        ) : (
          <>
            <Route path="/" element={<ScoreboardPage />} />
            <Route path="/races" element={<RacesPage />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/race" element={<RacePage />} />
            <Route path="/race-bet" element={<RaceBet />} />
            <Route path="/race-bet/result" element={<RaceBet />} />
            <Route path="/qualifying-bet/" element={<QualifyingBet />} />
            <Route path="/qualifying-bet/result" element={<QualifyingBet />} />
            <Route path="/sprint-bet/" element={<SprintBet />} />
            <Route path="/sprint-bet/result" element={<SprintBet />} />
          </>
        )}

        {userState.isAdmin && (
          <>
            <Route path="/register-admin" element={<RegisterAdmin />} />
            <Route path="/random-bets" element={<RandomBetsPage />} />
          </>
        )}
      </Routes>
    </Router>
  );
};

export const Navigator = observer(_Navigator);
