import { network } from "./";
import { logger } from "../utils";
import { IQualifyingBet, IRace, RaceTypes, User } from "../types";
import { AxiosResponse } from "axios";

const BASE = "qualifying-bet";

export const getQualifyingBet = async (id: string) => {
  try {
    const { data } = await network.get<IQualifyingBet>(`${BASE}/${id}`);
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};

export const getAllQualifyingsBets = async () => {
  try {
    const { data } = await network.get<IQualifyingBet[]>(`${BASE}/all`);
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};

export const getAllMissingQualifyingBets = async (
  uId: string,
  type: RaceTypes
) => {
  try {
    const { data } = await network.get<{ user: User; missingBets: IRace[] }>(
      `${BASE}/missing-bets/${uId}/type/${type}`
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};

export const postQualifyingBet = async (
  qualifying: IQualifyingBet
): Promise<AxiosResponse<IQualifyingBet>> =>
  await network.post(`${BASE}`, qualifying);

export const updateQualifyingBet = async (
  qualifying: IQualifyingBet
): Promise<AxiosResponse<IQualifyingBet>> =>
  await network.put(`${BASE}/${qualifying._id}`, qualifying);
