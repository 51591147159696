import { isPast } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FieldContainer } from "../components/FieldContainer";
import { Button, ButtonTypes } from "../generics/Button";
import { IconNames } from "../generics/Icon";
import Ranks from "../generics/Ranks";
import { Spinner } from "../generics/Spinner";
import {
  useAppDispatch,
  useAppSelector,
  useNavigateSearch,
  useQuery,
  useRaces,
} from "../hooks";
import {
  getAllQualifyingBetsAction,
  getAllQualifyingResultsAction,
  postQualifyingBetAction,
  postQualifyingResultAction,
  updateQualifyingBetAction,
  updateQualifyingResultAction,
} from "../redux/actions";
import { IQualifyingBet, IRace, QualifyingResult, RaceTypes } from "../types";
import { driverRanksScore, driverRanksTotalScore } from "../utils/score";
import { GenericPage } from "./GenericPage";
import { observer } from "mobx-react-lite";
import { useUser } from "../hooks/useUser";

const initialState: IQualifyingBet | QualifyingResult = {
  raceId: "",
  driverRanks: [],
};

const _QualifyingBet: React.FC = () => {
  const userState = useUser();
  const raceState = useRaces();

  const navigate = useNavigateSearch();
  const { query, pathname } = useQuery();
  const dispatch = useAppDispatch();

  const results = useMemo(
    () => pathname?.split("/")?.[2] === "result",
    [pathname]
  );
  const raceId = useMemo(() => query.get("raceId") as string, [query]);

  const race = raceState.races.find((race) => race._id === raceId) as IRace;

  const editable = useMemo(
    () =>
      results
        ? userState.isAdmin
        : !isPast(new Date(race?.qualifyingDate as Date)),
    [race, results]
  );

  const [formData, setFormData] = useState<IQualifyingBet | QualifyingResult>(
    initialState
  );

  useEffect(() => {
    dispatch(getAllQualifyingBetsAction());
    dispatch(getAllQualifyingResultsAction());
  }, [dispatch]);

  const isLoading = useAppSelector((state) => {
    if (results) {
      return state.qualifyingResults?.isLoading as boolean;
    } else {
      return state.qualifyingBets?.isLoading as boolean;
    }
  });

  const qualifyingBet = useAppSelector(
    (state) =>
      state.qualifyingBets?.qualifyingBets?.find(
        (bet) => bet.raceId === raceId
      ) as IQualifyingBet
  );
  const qualifyingResult = useAppSelector(
    (state) =>
      state.qualifyingResults?.qualifyingResults?.find(
        (result) => result.raceId === raceId
      ) as QualifyingResult
  );

  useEffect(() => {
    setFormData(
      (results ? qualifyingResult : qualifyingBet) ?? {
        ...initialState,
        raceId,
      }
    );
  }, [qualifyingResult, qualifyingBet, raceId, results]);

  const handleChange = ({ name, value }: { name: string; value: any }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    if (results) {
      dispatch(
        formData._id
          ? updateQualifyingResultAction(formData)
          : postQualifyingResultAction(formData)
      );
    } else {
      dispatch(
        formData._id
          ? updateQualifyingBetAction(formData)
          : postQualifyingBetAction(formData)
      );
    }
  };

  const driverRanksScoreTotal = useMemo(
    () =>
      driverRanksTotalScore(
        driverRanksScore(
          qualifyingBet?.driverRanks ?? [],
          qualifyingResult?.driverRanks ?? []
        ) ?? []
      ),
    [qualifyingBet, qualifyingResult]
  );

  const completed = useMemo(
    () => formData?.driverRanks?.length === (results ? 20 : 10),
    [formData, results]
  );

  const handleBack = () => {
    navigate("/race", { raceId });
  };

  return (
    <GenericPage
      title="qualifying"
      subtitle={results ? "results" : ""}
      icon={!!formData._id ? IconNames.SUCCESS : undefined}
    >
      <Spinner loading={isLoading}>
        <RaceContainer>
          <FieldContainer
            icon={IconNames.RANKING}
            completed={formData?.driverRanks?.length === (results ? 20 : 10)}
            score={results ? undefined : driverRanksScoreTotal}
          >
            <Ranks
              value={formData.driverRanks}
              editable={editable}
              raceId={raceId}
              type={RaceTypes.QUALIFYING}
              length={results ? 20 : 10}
              onChange={(value) => handleChange({ name: "driverRanks", value })}
            ></Ranks>
          </FieldContainer>

          {editable && (
            <Button
              type={ButtonTypes.PRIMARY}
              onClick={handleSubmit}
              text="Submit"
              disabled={isLoading || !completed}
            />
          )}
          <Button
            type={ButtonTypes.SECONDARY}
            onClick={handleBack}
            text="Back"
            disabled={isLoading}
          />
        </RaceContainer>
      </Spinner>
    </GenericPage>
  );
};
export const QualifyingBet = observer(_QualifyingBet);

const RaceContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`;
