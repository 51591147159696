import React from "react";
import { RacesProvider } from "../hooks";
import { UserProvider } from "../hooks/useUser";

export const Providers: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <UserProvider>
      <RacesProvider>{children}</RacesProvider>
    </UserProvider>
  );
};
