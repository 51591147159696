import { createSlice } from '@reduxjs/toolkit';
import { RaceResult } from '../../types';
import { getAllRaceResultsAction, postRaceResultAction, updateRaceResultAction } from '../actions';

interface IRaceResultData {
    raceResults: RaceResult[]
    isLoading: boolean
}

const raceResultsSlice = createSlice({
    name: 'qualifyingResults',
    initialState: {
        raceResults: [],
        isLoading: false
    } as IRaceResultData,
    reducers: {



    },
    extraReducers: builder => {
        // getAllRaceResultsAction
        builder.addCase(getAllRaceResultsAction.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.raceResults = payload
        })
        builder.addCase(getAllRaceResultsAction.pending, (state, { payload }) => {
            state.isLoading = true
        })

        // postRaceResultAction
        builder.addCase(postRaceResultAction.fulfilled, (state, { payload }) => {
            // Add user to the state array
            state.isLoading = false
            state.raceResults.push(payload)
        })
        builder.addCase(postRaceResultAction.pending, (state, { payload }) => {
            // Add user to the state array
            state.isLoading = true
        })

        // updateRaceResultAction
        builder.addCase(updateRaceResultAction.fulfilled, (state, { payload }) => {
            // Add user to the state array
            state.isLoading = false
            state.raceResults = state.raceResults.map(result => result._id === payload._id ? { ...payload } : { ...result })
        })
        builder.addCase(updateRaceResultAction.pending, (state, { payload }) => {
            // Add user to the state array
            state.isLoading = true
        })

    }
});

export default raceResultsSlice.reducer;
