import { network } from "./"
import { logger } from '../utils';
import { Team } from "../types";

const BASE = "team"

export const getTeam = async (id: string) => {
  try {
    const { data } = await network.get<Team>(
      `${BASE}/${id}`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};


export const getAllTeams = async () => {
  try {
    const { data } = await network.get<Team[]>(
      `${BASE}`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return [];
  }
};

// export const postTeam = async (team: Team) => {
//   try {
//     const { data } = await network.post<{ success: boolean }>(
//       `${BASE}`,
//       team,
//     );
//     return data.success
//   } catch (e) {
//     logger.error(e); //TODO uncomment
//     return null;
//   }
// }