import { createSlice } from '@reduxjs/toolkit';
import { SprintResult } from "../../types"
import { getAllSprintResultsAction, postSprintResultAction, updateSprintResultAction } from '../actions';

interface ISprintResultData {
    sprintResults: SprintResult[];
    isLoading: boolean;
}

const SprintResultsSlice = createSlice({
    name: 'SprintResults',
    initialState: {
        sprintResults: [],
        isLoading: false
    } as ISprintResultData,
    reducers: {



    },
    extraReducers: builder => {
        // getAllSprintResultsAction
        builder.addCase(getAllSprintResultsAction.fulfilled, (state, { payload }) => {
            state.sprintResults = payload
            state.isLoading = false
        })
        builder.addCase(getAllSprintResultsAction.pending, (state, { payload }) => {
            state.isLoading = true
        })

        // postSprintResultAction
        builder.addCase(postSprintResultAction.fulfilled, (state, { payload }) => {
            // Add user to the state array
            state.isLoading = false;
            state.sprintResults.push(payload)
        })
        builder.addCase(postSprintResultAction.pending, (state, { payload }) => {
            // Add user to the state array
            state.isLoading = true
        })

        // updateSprintResultAction
        builder.addCase(updateSprintResultAction.fulfilled, (state, { payload }) => {
            // Add user to the state array
            state.isLoading = false;
            state.sprintResults = state.sprintResults.map(result => result._id === payload._id ? { ...payload } : { ...result })
        })
        builder.addCase(updateSprintResultAction.pending, (state, { payload }) => {
            // Add user to the state array
            state.isLoading = true
        })

    }
});

export default SprintResultsSlice.reducer;
