import React, { useEffect, useMemo, useState } from "react";
import { GenericPage } from "./GenericPage";
import { Box, Card, Dropdown, Loader, Text } from "@wix/design-system";
import { observer } from "mobx-react-lite";
import {
  createRandomBet,
  getAllMissingQualifyingBets,
  getAllUsers,
} from "../api";
import { IRace, RaceTypes, User, UserRole } from "../types";
import { RandomBet } from "../components/RandomBet";

const _RandomBetsPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedRaceType, setSelectedRaceType] = useState<RaceTypes>(
    RaceTypes.QUALIFYING
  );
  const [missingBets, setMissingBets] = useState<{
    user: User;
    missingBets: IRace[];
  } | null>();

  useEffect(() => {
    getAllUsers().then((res) => {
      setUsers(res!);
      setSelectedUser(res![0]);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (selectedUser)
      getAllMissingQualifyingBets(selectedUser._id, selectedRaceType).then(
        (res) => {
          setMissingBets(res);
          setLoading(false);
        }
      );
  }, [selectedUser, selectedRaceType]);

  const options = useMemo(() => {
    return users.map((user) => ({
      id: user._id,
      value: user.username,
    }));
  }, [users]);

  const createRandom = (raceId: string) => {
    createRandomBet(selectedUser!._id, selectedRaceType, raceId).then(() => {
      setMissingBets({
        user: selectedUser!,
        missingBets: missingBets!.missingBets.filter((b) => b._id !== raceId),
      });
    });
  };

  return (
    <GenericPage>
      <Card>
        <Card.Header title="Random Bets" />
        <Card.Divider />
        <Card.Content>
          <Box align="center" direction="vertical" gap={2}>
            <Box gap={2}>
              <Dropdown
                options={options}
                selectedId={selectedUser?._id}
                onSelect={(option) =>
                  setSelectedUser(users.find((u) => u._id === option.id)!)
                }
              />
              <Dropdown
                options={[
                  { id: RaceTypes.QUALIFYING, value: "Qualifying" },
                  { id: RaceTypes.SPRINT, value: "Sprint" },
                  { id: RaceTypes.RACE, value: "Race" },
                ]}
                selectedId={selectedRaceType}
                onSelect={(option) =>
                  setSelectedRaceType(option.id as RaceTypes)
                }
              />
            </Box>
            <Box align="center" direction="vertical" gap={2}>
              {loading ? (
                <Loader size="medium" />
              ) : missingBets?.missingBets.length ? (
                <RandomBet
                  missingBets={missingBets?.missingBets!}
                  createBet={(id) => {
                    createRandom(id);
                  }}
                />
              ) : (
                <Text>No missing bets</Text>
              )}
            </Box>
          </Box>
        </Card.Content>
      </Card>
    </GenericPage>
  );
};

export const RandomBetsPage = observer(_RandomBetsPage);
