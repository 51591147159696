import { Badge, BadgeSkin, Box, Card, Modal } from "@wix/design-system";
import React from "react";
import { RACE_STATUS_SKIN } from "../utils";
import { RaceStatus } from "../types";
import { RacesState } from "../state";
import { observer } from "mobx-react-lite";

interface StatusModalProps {
  state: RacesState;
}
const _StatusModal: React.FC<StatusModalProps> = ({ state }) => {
  const handleStatusClicked = (status: RaceStatus) => {
    state.updateRace({ status });
  };

  return (
    <Modal
      isOpen={state.openStatusModal}
      onRequestClose={() => (state.openStatusModal = false)}
      shouldCloseOnOverlayClick
      shouldDisplayCloseButton
      screen="mobile"
    >
      <Box
        direction="vertical"
        gap={3}
        padding={10}
        backgroundColor="#fff"
        borderRadius={5}
        height="50%"
        width="100%"
        verticalAlign="middle"
      >
        <Badge
          skin={RACE_STATUS_SKIN[RaceStatus.ACTIVE] as BadgeSkin}
          onClick={(_) => handleStatusClicked(RaceStatus.ACTIVE)}
        >
          {RaceStatus.ACTIVE}
        </Badge>
        <Badge
          skin={RACE_STATUS_SKIN[RaceStatus.CANCELED] as BadgeSkin}
          onClick={(_) => handleStatusClicked(RaceStatus.CANCELED)}
        >
          {RaceStatus.CANCELED}
        </Badge>
        <Badge
          skin={RACE_STATUS_SKIN[RaceStatus.COMPLETED] as BadgeSkin}
          onClick={(_) => handleStatusClicked(RaceStatus.COMPLETED)}
        >
          {RaceStatus.COMPLETED}
        </Badge>
        <Badge
          skin={RACE_STATUS_SKIN[RaceStatus.PENDING] as BadgeSkin}
          onClick={(_) => handleStatusClicked(RaceStatus.PENDING)}
        >
          {RaceStatus.PENDING}
        </Badge>
      </Box>
    </Modal>
  );
};

export const StatusModal = observer(_StatusModal);
