import { makeAutoObservable } from "mobx";
import { IRace, RaceStatus, ViewTypes } from "../types";
import { getAllRaces, postRace, updateRace } from "../api";

export class RacesState {
  private _races: IRace[];
  private _isLoading: boolean;

  private _raceToUpdateId: string | undefined;
  private _viewType: ViewTypes;
  private _openCreateRaceModal: boolean = false;
  private _openStatusModal: boolean = false;

  constructor() {
    this._races = [];
    this._isLoading = false;
    this._viewType = "upcoming";

    makeAutoObservable(this);

    this.init();
  }

  get races() {
    return this._races;
  }

  get isLoading() {
    return this._isLoading;
  }

  get viewType() {
    return this._viewType;
  }

  get openCreateRaceModal() {
    return this._openCreateRaceModal;
  }

  get openStatusModal() {
    return this._openStatusModal;
  }

  get visibleRaces() {
    return this.races.filter((race) =>
      [RaceStatus.ACTIVE, RaceStatus.PENDING].includes(race.status)
        ? this.viewType === "upcoming"
        : this.viewType === "past"
    );
  }

  set viewType(type: ViewTypes) {
    this._viewType = type;
  }

  set openCreateRaceModal(open: boolean) {
    this._openCreateRaceModal = open;
  }

  set openStatusModal(open: boolean) {
    this._openStatusModal = open;
  }

  set raceToUpdateId(id: string | undefined) {
    this._raceToUpdateId = id;
  }

  private async init() {
    this._isLoading = true;
    this.getAllRaces()
      .then((races) => {
        this._races = races;
      })
      .finally(() => (this._isLoading = false));
  }

  private getAllRaces = async () => {
    return getAllRaces();
  };

  createRace = async (race: Partial<IRace>) => {
    this._isLoading = true;
    postRace(race)
      .then(({ data }) => {
        this._races.push(data);
        this.openCreateRaceModal = false;
      })
      .finally(() => {
        this._isLoading = false;
      });
  };

  updateRace = async (fieldsToUpdate: Partial<IRace>) => {
    this._isLoading = true;
    updateRace(this._raceToUpdateId!, fieldsToUpdate)
      .then(() => {
        this._races = this._races.map((race) => {
          if (race._id.toString() === this._raceToUpdateId!.toString())
            return { ...race, ...fieldsToUpdate };
          return { ...race };
        });
        this._raceToUpdateId = undefined;
        this.openStatusModal = false;
      })
      .finally(() => {
        this._isLoading = false;
      });
  };
}
