import { BadgeSkin } from "@wix/design-system";
import { RaceStatus } from "../types";

export const RACE_STATUS_COLOR = {
  [RaceStatus.PENDING]: "#e0e0e0",
  [RaceStatus.COMPLETED]: "#2196f3",
  [RaceStatus.ACTIVE]: "#4caf50",
  [RaceStatus.CANCELED]: "#f44336",
};

export const RACE_STATUS_SKIN = {
  [RaceStatus.PENDING]: "neutralLight" as BadgeSkin,
  [RaceStatus.COMPLETED]: "neutralStandard" as BadgeSkin,
  [RaceStatus.ACTIVE]: "neutralSuccess" as BadgeSkin,
  [RaceStatus.CANCELED]: "neutralDanger" as BadgeSkin,
};

export const convertDateTimeToDateObject = (date: string, time: string) => {
  // Split date and time strings
  if (!date || !time) return;
  const [year, month, day] = date.split("-");
  const [hours, minutes] = time.split(":");

  // Create a new Date object
  return new Date(
    Number(year),
    Number(month) - 1,
    Number(day),
    Number(hours),
    Number(minutes)
  );
};
