import React, { useState } from "react";
import RanksModal from "../modals/RanksModal";
import { RaceTypes } from "../types";

interface IRanks {
  value: { driverId: string; rank: number }[];
  editable: boolean;
  type: RaceTypes;
  raceId: string;
  length: number;
  onChange: (e: any) => void;
}

const Ranks: React.FC<IRanks> = ({
  value,
  editable,
  type,
  raceId,
  length,
  onChange,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleSelected = ({
    completed,
    ranks,
  }: {
    completed: boolean;
    ranks: { driverId: string; rank: number }[];
  }) => {
    if (completed) {
      onChange(ranks);
    }
    setModalOpen(false);
  };
  const handleClick = () => {
    setModalOpen(true);
  };

  return (
    <div
      onClick={handleClick}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      COMPLETED
      <RanksModal
        length={length}
        open={modalOpen}
        ranks={value}
        editable={editable}
        type={type}
        raceId={raceId}
        selected={handleSelected}
      ></RanksModal>
    </div>
  );
};

export default Ranks;
