import React, { useState } from "react";
import { GenericPage } from "./GenericPage";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Card,
  FormField,
  Input,
  Loader,
} from "@wix/design-system";
import { useUser } from "../hooks/useUser";
import { Add } from "@wix/wix-ui-icons-common";

const _Profile: React.FC = () => {
  const userState = useUser();
  const [password, setPassword] = useState("");

  const submit = async () => {
    userState.updatePassword(password).then(() => {
      setPassword("");
    });
  };
  return (
    <GenericPage>
      <Card hideOverflow>
        <Card.Header
          title="Profile"
          suffix={
            <Box verticalAlign="middle">
              <Button
                size="small"
                skin="destructive"
                disabled={!password || userState.loading}
                onClick={submit}
                prefixIcon={
                  userState.loading ? <Loader size="tiny" /> : <Add />
                }
              >
                {userState.loading ? "" : "Update"}
              </Button>
            </Box>
          }
        />
        <Card.Divider />
        <Card.Content>
          <Box align="center" direction="vertical" gap={2}>
            <FormField label="New password" required>
              <Input
                type="password"
                value={password}
                onChange={({ target: { value: val } }) => setPassword(val)}
              />
            </FormField>
          </Box>
        </Card.Content>
      </Card>
    </GenericPage>
  );
};

export const Profile = observer(_Profile);
