import {
  IError,
  ISuccess,
  User,
  LoginReturnType,
  PassAndUsername,
  Tokens,
  UserAndTokens,
  Maybe,
} from "../types";
import { logger } from "../utils";
import { ACCESS_TOKEN_KEY, network } from "./";
import Cookies from "js-cookie";

const BASE = "/auth";

const getRefreshOrThrow = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    throw new Error("no refresh token found");
  }
  return refreshToken;
};

export const logErrorToService = async (error: Error, info: string) => {
  try {
    const prevUserString = await localStorage.getItem("currUser");
    let prevUser;
    if (prevUserString) {
      prevUser = JSON.parse(prevUserString);
    }
    const payload = {
      platform: "web",
      error,
      info, //@ts-ignore
      user: prevUser ? prevUser._id : null,
    };
    const {
      data: { created },
    } = await network.post<{ created: IError }>(`${BASE}/error`, payload);
    return created;
  } catch {
    return false;
  }
};

export const getAccessToken = async () => {
  try {
    const refreshToken = await getRefreshOrThrow();
    const {
      data: { accessToken },
    } = await network.post<Pick<Tokens, "accessToken">>(`${BASE}/get-token`, {
      refreshToken,
    });
    Cookies.set(ACCESS_TOKEN_KEY, accessToken);
    return accessToken;
  } catch {}
};
export const loginByPass = async (
  payload: PassAndUsername
  // @ts-ignore
): LoginReturnType => {
  try {
    const {
      data: { user, accessToken, refreshToken },
    } = await network.post<UserAndTokens>(`${BASE}/login`, payload);
    Cookies.set(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("currUser", JSON.stringify(user));
    return user;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};

export const logout = async (): Promise<boolean> => {
  try {
    const {
      data: { success },
    } = await network.post<ISuccess>(`${BASE}/logout`);
    if (success) {
      // remove from local storage
      localStorage.clear();
      Cookies.remove(ACCESS_TOKEN_KEY);
    }
    return success;
  } catch (e) {
    logger.error(e);
    return false;
  }
};
export const register = async (
  payload: Pick<User, "username" | "role">
  // @ts-ignore
): LoginReturnType => {
  try {
    const {
      data: { user, accessToken, refreshToken },
    } = await network.post<UserAndTokens>(`${BASE}/register`, {
      ...payload,
      password: "123456",
    });
    return user;
  } catch (e) {
    logger.error(e);
    return null;
  }
};

export const updatePassword = async (password: string): Promise<boolean> => {
  try {
    const { data } = await network.post<{ success: boolean }>(
      `${BASE}/update-password`,
      { password }
    );
    return !!data?.success;
  } catch (e) {
    logger.error(e);
    return false;
  }
};
// @ts-ignore
export const loginWithToken = async (): LoginReturnType => {
  try {
    const refreshToken = await getRefreshOrThrow();

    const {
      data: { accessToken, user },
    } = await network.post<Pick<UserAndTokens, "user" | "accessToken">>(
      `${BASE}/login-with-token`,
      { refreshToken }
    );
    console.log({
      accessToken,
      user,
    });

    Cookies.set(ACCESS_TOKEN_KEY, accessToken);
    return user;
  } catch (error: any) {
    logger.error(error.message); //TODO uncomment
    return null;
  }
};
// @ts-ignore
export const editUser = async (payload: Partial<User>): LoginReturnType => {
  try {
    const {
      data: { user },
    } = await network.put<Record<"user", User>>(`${BASE}/edit`, payload);
    return user;
  } catch (e) {
    logger.error(e);
    return null;
  }
};

export const getAllUsers = async (): Promise<User[] | null> => {
  try {
    const { data } = await network.get<User[]>(`user`);
    return data;
  } catch (e) {
    logger.error(e);
    return null;
  }
};
