import React, { useEffect, useMemo, useState } from "react";
import SelectModal from "../modals/SelectModal";
import drivers from "../mocks/drivers.json";
import styled from "styled-components";
import DriverCard from "../components/DriverCard";
import Label from "./Label";
import { useAppSelector } from "../hooks";
import { Driver } from "../types";
import { Icon, IconNames } from "./Icon";
import { ScoreStatus } from "../utils/score";
import { Score } from "../components/DriverRanksResult";
import { Container, StatusContainer } from "../components/FieldContainer";

interface ISelect {
  editable: boolean;
  value: string;
  onChange: (e: any) => void;
}

const Select: React.FC<ISelect> = ({ editable, value, onChange }) => {
  const { drivers } = useAppSelector((state) => state.drivers);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const driver = useMemo(
    () => drivers?.find((driver) => driver._id === value) as Driver,
    [drivers, value]
  );

  const handleSelected = (e: any) => {
    if (e) {
      onChange(e);
    }
    setModalOpen(false);
  };
  const handleClick = () => {
    if (!editable) return;
    setModalOpen(true);
  };
  return (
    <>
      <DriverCard _id={driver?._id} clicked={handleClick} />
      <SelectModal open={modalOpen} selected={handleSelected}></SelectModal>
    </>
  );
};

export default Select;
