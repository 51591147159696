import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Score } from "../components/DriverRanksResult";
import { Container, StatusContainer } from "../components/FieldContainer";
import { ScoreStatus } from "../utils/score";
import { Icon, IconNames } from "./Icon";
import Label from "./Label";

interface IInput {
  value: number;
  editable: boolean;
  onChange: (e: any) => void;
}

const Input: React.FC<IInput> = ({ editable, value, onChange }) => {
  const [number, setNumber] = useState<number>(value);

  useEffect(() => {
    setNumber(value);
  }, [value]);
  const handleChange = (e: any) => {
    if (!editable) return;
    let newValue = number + e;
    if (0 <= newValue && newValue <= 20) {
      setNumber(newValue);
      onChange(newValue);
    }
  };

  return (
    <InputContainer>
      <CircleButton
        onClick={() => handleChange(-1)}
        disabled={number === 0}
      ></CircleButton>
      <CountValue>{number}</CountValue>
      <CircleButton
        onClick={() => handleChange(1)}
        disabled={number === 20}
      ></CircleButton>
    </InputContainer>
  );
};

export default Input;

const InputContainer = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 100%;
  padding: 0.5em;
`;

export const Customm = styled.div`
  background-color: #1e609a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

const CircleButton = styled.div<{ disabled: boolean }>`
  background-color: ${(props) => (props.disabled ? "#89898e" : "#e10600")};
  color: #fff;
  height: 100%;
  aspect-ratio: 1 / 1;
  /* height: 25px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: border-box;
`;

const CountValue = styled.span`
  min-width: 3em;
  text-align: center;
  padding: 0 4px;
`;
