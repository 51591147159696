import React from "react";
import { GenericPage } from "./GenericPage";
import { Box, Card, IconButton, SegmentedToggle } from "@wix/design-system";
import { List, Archive, Add } from "@wix/wix-ui-icons-common";
import { useNavigateSearch, useRaces } from "../hooks";
import { IRace, ViewTypes } from "../types";
import { RaceInfoContainerCard } from "../components/RaceInfoContainerCard";
import { StatusModal } from "../modals/StatusModal";
import { useSettings } from "../providers/SettingsProvider";
import { CreateRaceModal } from "../modals/CreateRaceModal";
import { observer } from "mobx-react-lite";
import { useUser } from "../hooks/useUser";

const _RacesPage: React.FC = () => {
  const { documentSizes } = useSettings();
  const state = useRaces();
  const userState = useUser();

  const navigate = useNavigateSearch();

  const handleClick = (race: IRace) => {
    navigate("/race", { raceId: race._id });
  };

  return (
    <GenericPage>
      <Card>
        <Card.Header
          title="Races"
          suffix={
            <Box gap="18px" verticalAlign="middle">
              <SegmentedToggle
                selected={state.viewType}
                onClick={(_, value) => (state.viewType = value as ViewTypes)}
              >
                <SegmentedToggle.Icon value={"upcoming"} tooltipText="Upcoming">
                  <List />
                </SegmentedToggle.Icon>
                <SegmentedToggle.Icon value={"past"} tooltipText="Past">
                  <Archive />
                </SegmentedToggle.Icon>
              </SegmentedToggle>
              <IconButton
                size="small"
                skin="dark"
                disabled={!userState.isAdmin}
                onClick={() => (state.openCreateRaceModal = true)}
              >
                <Add />
              </IconButton>
            </Box>
          }
        />
        <Card.Divider />
        <Box
          overflowY="auto"
          direction="vertical"
          maxHeight={`${documentSizes.height - 160}px`}
        >
          {state.visibleRaces.map((race) => (
            <RaceInfoContainerCard
              state={state}
              race={race}
              isAdmin={userState.isAdmin}
              onClick={() => handleClick(race)}
            />
          ))}
        </Box>
      </Card>
      <StatusModal state={state} />
      <CreateRaceModal state={state} />
    </GenericPage>
  );
};

export const RacesPage = observer(_RacesPage);
