import { network } from "./"
import { logger } from '../utils';
import { IScore } from "../types";

const BASE = "score"

export const getAllScores = async () => {
  try {
    const { data } = await network.get<IScore[]>(
      `${BASE}/all`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return [];
  }
};
