import React from "react";
import { Button as WDSButton } from "@wix/design-system";

export enum ButtonTypes {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}
interface IButton {
  type: ButtonTypes;
  text: string;
  disabled?: boolean;
  onClick: () => void;
}
export const Button: React.FC<IButton> = ({
  type,
  text,
  disabled = false,
  onClick,
}) => {
  return (
    <WDSButton
      skin="destructive"
      priority={type}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </WDSButton>
  );
};
