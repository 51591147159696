import React from "react";
import { Box, Card, Divider, IconButton, Text } from "@wix/design-system";
import { ArrowRight } from "@wix/wix-ui-icons-common";
interface RaceDetailsContainerCardProps {
  name: string;
  day: string;
  month: string;
  time?: string;
  onClick: () => void;
}

const RaceDetailsContainerCard: React.FC<RaceDetailsContainerCardProps> = ({
  name,
  day,
  month,
  time,
  onClick,
}) => {
  return (
    <Card>
      <Box
        borderRadius={4}
        padding="15px 0"
        width="100%"
        boxShadow="0px 1px 2px #000"
      >
        <Box direction="vertical" align="center" width="65px">
          <Box>
            <Text size="medium" weight="bold">
              {day}
            </Text>
          </Box>
          <Box
            backgroundColor="#ededed"
            padding="2px 10px"
            margin="0 auto"
            borderRadius="15px"
            lineHeight="15px"
            letterSpacing="0.5px"
            textTransform="uppercase"
          >
            <Text color="#67676d" size="tiny">
              {month}
            </Text>
          </Box>
        </Box>
        <Divider direction="vertical" />
        <Box align="space-between" flexGrow={1}>
          <Box
            direction="vertical"
            gap="5px"
            paddingLeft="10px"
            verticalAlign="middle"
          >
            <Text size="medium" weight="bold">
              {name}
            </Text>
            {time && (
              <Text size="tiny" color="#67676d">
                {time}
              </Text>
            )}
          </Box>
          <Box align="center" verticalAlign="middle" padding="5px 10px">
            <IconButton
              onClick={() => onClick()}
              skin="dark"
              priority="secondary"
            >
              <ArrowRight color="#000" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default RaceDetailsContainerCard;
