import {
  Badge,
  BadgeSkin,
  Box,
  Divider,
  Heading,
  TableListItem,
  Text,
} from "@wix/design-system";
import React from "react";
import { ArrowRight } from "@wix/wix-ui-icons-common";
import { IRace } from "../types";
import { RACE_STATUS_SKIN } from "../utils";
import { format } from "date-fns";
import { alpha2countries } from "../utils/utils";
import { RacesState } from "../state";

interface RaceInfoContainerCardProps {
  state: RacesState;
  race: IRace;
  isAdmin: boolean;
  onClick: () => void;
}
export const RaceInfoContainerCard: React.FC<RaceInfoContainerCardProps> = ({
  state,
  race: { _id, name, country, status, round, qualifyingDate, raceDate },
  isAdmin,
  onClick,
}) => {
  const handleStatusClicked = (
    _: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (isAdmin) {
      _.stopPropagation();
      state.raceToUpdateId = _id;
      state.openStatusModal = true;
    }
  };

  return (
    <TableListItem
      onClick={onClick}
      options={[
        {
          value: (
            <Box>
              <Box
                width="100%"
                position="relative"
                direction="vertical"
                padding="10px 10px 10px 0"
                gap="15px"
                borderRight="2px solid #000"
                borderTop="2px solid #000"
                borderTopRightRadius="10px"
              >
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  transform="translateY(-50%)"
                  paddingRight="10px"
                  backgroundColor="#fff"
                >
                  <Text
                    skin="error"
                    weight="bold"
                    size="tiny"
                  >{`ROUND ${round}`}</Text>
                </Box>
                <Box width="100%" verticalAlign="middle" align="space-between">
                  <Box direction="vertical" align="left">
                    <Heading size="large">{`${format(
                      new Date(qualifyingDate),
                      "dd"
                    )}-${format(new Date(raceDate), "dd")}`}</Heading>
                    <Box
                      backgroundColor="#000"
                      padding="2px 5px 1px"
                      border-radius="5px"
                      lineHeight="15px"
                      letterSpacing="0.5px"
                      textTransform="uppercase"
                    >
                      <Text light weight="bold">
                        {format(new Date(qualifyingDate), "LLL")}
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    verticalAlign="middle"
                    width="150px"
                    align="space-between"
                  >
                    <Badge
                      skin={RACE_STATUS_SKIN[status] as BadgeSkin}
                      onClick={(_) => handleStatusClicked(_)}
                    >
                      {status.toUpperCase()}
                    </Badge>
                    <Box
                      className={`fi fi-${
                        alpha2countries[
                          country.toLowerCase().trim().split(" ").join("")
                        ] ?? "il"
                      }`}
                      height="30px"
                      width="40px"
                      backgroundSize="cover"
                      borderRadius={2}
                      boxShadow="0px 2px 4px #000"
                    />
                  </Box>
                </Box>
                <Divider direction="horizontal" />
                <Box direction="vertical">
                  <Box gap={1}>
                    <Heading size="medium">{country}</Heading>
                    <ArrowRight />
                  </Box>
                  <Text size="small">{name}</Text>
                </Box>
              </Box>
            </Box>
          ),
          width: "100%",
        },
      ]}
    />
  );
};
