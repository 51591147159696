import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ThemeProvider } from "styled-components";
import theme from "./theme/theme";
import GlobalStyles from "./GlobalStyles";
import { ToastContainer } from "react-toastify";
import { WixDesignSystemProvider } from "@wix/design-system";
import { SettingsProvider } from "./providers/SettingsProvider";

import "@wix/design-system/styles.global.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { Providers } from "./providers";
import { Navigator } from "./pages";

function App() {
  return (
    <SettingsProvider>
      <Providers>
        <WixDesignSystemProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <ToastContainer />
            <Provider store={store}>
              <Navigator />
            </Provider>
          </ThemeProvider>
        </WixDesignSystemProvider>
      </Providers>
    </SettingsProvider>
  );
}

export default App;
