import { makeAutoObservable } from "mobx";
import { loginByPass, loginWithToken, logout, updatePassword } from "../api";
import { PassAndUsername, User } from "../types";

export class UserState {
  private _user: User | undefined;
  private _loadingAuth: boolean;
  private _isSignedIn: boolean;
  private _isAdmin: boolean;
  private _loading: boolean;

  constructor() {
    this._user = undefined;
    this._loadingAuth = false;
    this._isSignedIn = false;
    this._isAdmin = false;
    this._loading = false;

    makeAutoObservable(this);

    this.init();
  }

  get loadingAuth() {
    return this._loadingAuth;
  }

  get isSignedIn() {
    return this._isSignedIn;
  }

  get isAdmin() {
    return this._isAdmin;
  }

  get loading() {
    return this._loading;
  }

  set authorized(user: User) {
    this._user = user;
    this._isSignedIn = true;
    this._isAdmin = user.role === "admin";
  }

  set loadingAuth(value: boolean) {
    this._loadingAuth = value;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  private async init() {
    this.loadingAuth = true;
    loginWithToken()
      .then((user) => {
        if (user) {
          this.authorized = user;
        }
      })
      .finally(() => {
        this.loadingAuth = false;
      });
  }

  login = ({ username, password }: PassAndUsername) => {
    this.loadingAuth = true;
    loginByPass({ username, password })
      .then((user) => {
        if (user) {
          this.authorized = user;
        }
      })
      .finally(() => {
        this.loadingAuth = false;
      });
  };

  logout = () => {
    this.loadingAuth = true;
    logout()
      .then((success) => {
        this._user = undefined;
        this._isSignedIn = false;
        this._isAdmin = false;
      })
      .finally(() => {
        this.loadingAuth = false;
      });
  };

  updatePassword = async (password: string) => {
    this.loading = true;
    return updatePassword(password)
      .then(() => {})
      .finally(() => {
        this.loading = false;
      });
  };
}
