import { network } from "./"
import { logger } from '../utils';
import { IRaceBet } from '../types';
import { AxiosResponse } from "axios";

const BASE = "race-bet"

export const getRaceBet = async (raceId: string) => {
  try {
    const { data } = await network.get<IRaceBet>(
      `${BASE}/${raceId}`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};


export const getAllRacesBets = async () => {
  try {
    const { data } = await network.get<IRaceBet[]>(
      `${BASE}/all`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};

export const postRaceBet = async (race: IRaceBet): Promise<AxiosResponse<IRaceBet>> =>
  await network.post(
    `${BASE}`,
    race,
  );
export const updateRaceBet = async (race: IRaceBet): Promise<AxiosResponse<IRaceBet>> =>
  await network.put(
    `${BASE}/${race._id}`,
    race,
  );