import {
  IQualifyingBet,
  IRaceBet,
  IRaceBetKeys,
  QualifyingResult,
  RaceResult,
} from "../types";

export enum ScoreStatus {
  MATCH = "match",
  NEAR = "near",
  NONE = "none",
}

const getEqual = (
  ranksResult: {
    driverId: any;
    rank: number;
  }[],
  rank: number
): any => {
  return ranksResult?.find((rankResult) => rankResult.rank === rank)?.driverId;
};

const getOneAboveUnder = (
  ranksResult: {
    driverId: any;
    rank: number;
  }[],
  rank: number
): any[] => {
  if (rank === 1)
    return [
      ranksResult?.find((rankResult) => rankResult.rank === rank + 1)?.driverId,
    ];
  if (rank === ranksResult?.length)
    return [
      ranksResult?.find((rankResult) => rankResult.rank === rank - 1)?.driverId,
    ];
  return [
    ranksResult?.find((rankResult) => rankResult.rank === rank + 1)?.driverId,
    ranksResult?.find((rankResult) => rankResult.rank === rank - 1)?.driverId,
  ];
};
export const driverRanksScore = (
  ranksBet: {
    driverId: any;
    rank: number;
  }[],
  ranksResult: {
    driverId: any;
    rank: number;
  }[]
): {
  driverId: any;
  rank: number;
  score: { total: number; status: ScoreStatus };
  driverResultId: any;
}[] => {
  const slicedRanksResult =
    [...ranksResult]
      ?.sort((a, b) => a.rank - b.rank)
      .slice(0, ranksBet?.length) ?? [];

  return ranksBet
    .map((rankBet, i) => {
      const { driverId, rank } = rankBet;
      const equal = getEqual(slicedRanksResult, rank);
      const aboveUnder = getOneAboveUnder(slicedRanksResult, rank);
      const status =
        driverId === equal
          ? ScoreStatus.MATCH
          : aboveUnder.includes(driverId)
          ? ScoreStatus.NEAR
          : ScoreStatus.NONE;
      return {
        ...rankBet,
        score: {
          status,
          total:
            status === ScoreStatus.MATCH
              ? 3
              : status === ScoreStatus.NEAR
              ? 1
              : 0,
        },
        driverResultId: equal,
      };
    })
    .sort((a, b) => a.rank - b.rank);
};

export const driverRanksTotalScore = (
  driverRanksScore: {
    driverId: any;
    rank: number;
    score: { total: number; status: ScoreStatus };
    driverResultId: any;
  }[]
): { status: ScoreStatus; value: number } => {
  const value = driverRanksScore.reduce(
    (prev, curr) => prev + curr.score.total,
    0
  );
  return { status: value > 0 ? ScoreStatus.MATCH : ScoreStatus.NONE, value };
};

const getScore = (
  key: IRaceBetKeys,
  raceBet: IRaceBet,
  raceResult: RaceResult
): { status: ScoreStatus; value: number } | undefined => {
  switch (key) {
    case "driverRanks":
      return;
    case "fastestLap":
    case "mvp":
    case "totalFinished":
      return raceBet[key] === raceResult[key]
        ? { status: ScoreStatus.MATCH, value: 3 }
        : { status: ScoreStatus.NONE, value: 0 };
    case "redFlag":
    case "safetyCar":
    case "vSafetyCar":
      return raceBet[key] === raceResult[key]
        ? { status: ScoreStatus.MATCH, value: 2 }
        : { status: ScoreStatus.NONE, value: 0 };
    case "firstToPenalty":
    case "firstToPit":
      return raceBet[key] === raceResult[key]
        ? { status: ScoreStatus.MATCH, value: 5 }
        : { status: ScoreStatus.NONE, value: 0 };
    default:
      return;
  }
};

export const getQualifyingBetScore = (
  qualifyingBet: IQualifyingBet,
  qualifyingResult: QualifyingResult
) => {
  return {
    score: 0,
    driverRanks: {
      score: 0,
    },
  };
};

export const getRaceScore = (
  raceBet: IRaceBet,
  raceResult: RaceResult
): { [key: string]: { status: ScoreStatus; value: number } } => {
  let score = {};
  Object.keys(raceBet).forEach((key) => {
    score = {
      ...score,
      [key]: getScore(key as IRaceBetKeys, raceBet, raceResult),
    };
  });
  return score;
};
