import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useAppSelector } from "../hooks";
import { DriverRank, RaceTypes } from "../types";
import { driverRanksScore, ScoreStatus } from "../utils/score";
import DriverCard from "./DriverCard";

interface Props {
  raceId: string;
  type: RaceTypes;
  ranks: DriverRank[];
}

const DriverRanksResult: React.FC<Props> = ({ raceId, type, ranks }) => {
  const [page, setPage] = useState<number>(1);

  const bet = useMemo(() => ranks, [ranks]);

  const result = useAppSelector((state) => {
    switch (type) {
      case RaceTypes.QUALIFYING:
        return state.qualifyingResults.qualifyingResults.find(
          (qualifyingResult) => qualifyingResult.raceId === raceId
        )?.driverRanks as DriverRank[];
      case RaceTypes.SPRINT:
        return state.sprintResults.sprintResults.find(
          (sprintResult) => sprintResult.raceId === raceId
        )?.driverRanks as DriverRank[];
      case RaceTypes.RACE:
        return state.raceResults.raceResults.find(
          (raceResult) => raceResult.raceId === raceId
        )?.driverRanks as DriverRank[];
      default:
        return [] as DriverRank[];
    }
  });
  const pages = useMemo(() => bet?.length / 10, [bet]);

  const driverRanksResult = useMemo(
    () => driverRanksScore(bet ?? [], result ?? []),
    [result, bet]
  );

  return (
    <RanksContainer>
      <RanksList>
        {driverRanksResult?.slice((page - 1) * 10, page * 10).map((driver) => (
          <Rank>
            <DriverCard key={driver.driverId} _id={driver.driverId} />
            <Details>
              <Position>{driver.rank}</Position>
              <Score status={driver.score?.status}>{driver.score?.total}</Score>
            </Details>
            <DriverCard
              key={driver.driverResultId + "result"}
              _id={driver.driverResultId}
            />
          </Rank>
        ))}
      </RanksList>
      <PagesContainer>
        {pages &&
          new Array(pages).fill("").map((el, i) => (
            <Page onClick={() => setPage(i + 1)} selected={page === i + 1}>
              {i + 1}
            </Page>
          ))}
      </PagesContainer>
    </RanksContainer>
  );
};

export default DriverRanksResult;

const RanksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
`;

const RanksList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Rank = styled.div`
  display: flex;
  background-color: #fff;
  align-items: center;
  padding: 5px;
  border: 1px solid black;
  gap: 5px;
  width: 100%;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: center;
`;
const Position = styled.div`
  padding: 0 2px;
  border: 1px dotted black;
  width: 20px;
`;

const PagesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 5px;
`;

const Page = styled.div<{ selected: boolean }>`
  cursor: pointer;
  font-size: 18px;
  font-weight: ${(props) => (props.selected ? "600" : "400")};
`;
export const Score = styled.div<{ status: ScoreStatus }>`
  height: 100%;
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) =>
    props.status === ScoreStatus.MATCH
      ? "green"
      : props.status === ScoreStatus.NEAR
      ? "red"
      : "white"};
`;
