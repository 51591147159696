import React from "react";
import styled from "styled-components";
// import { Finish, Penalty, Pit, Ranking, Speed, Vote } from "../static/icons";
import {
  F1Logo,
  Finish,
  Home,
  Logout,
  Penalty,
  Pit,
  Profile,
  Ranking,
  RedFlag,
  Registration,
  SafetyCar,
  Speed,
  Success,
  Vote,
  VSafetyCar,
  Mvp,
} from "../static";

export enum IconNames {
  FINISH = "finish",
  PENALTY = "penalty",
  PIT = "pit",
  RANKING = "ranking",
  SPEED = "speed",
  VOTE = "vote",
  YELLOW_FLAG = "yellowflag",
  RED_FLAG = "redflag",
  SAFETY_CAR = "safetycar",
  V_SAFETY_CAR = "vsafetycar",
  F1_LOGO = "f1logo",
  HOME = "home",
  REGISTRATION = "registration",
  LOGOUT = "logout",
  PROFILE = "profile",
  SUCCESS = "success",
  MVP = "mvp",
}

export enum IconSizes {
  S = "small",
  M = "medium",
  L = "large",
}
interface IIcon {
  name: IconNames;
  size?: IconSizes;
}
export const Icon: React.FC<IIcon> = ({ name, size = IconSizes.S }) => {
  const [width, height] = (() => {
    switch (size) {
      case IconSizes.S:
        return ["20px", "20px"];
      case IconSizes.M:
        return ["40px", "40px"];
      case IconSizes.L:
        return ["60px", "60px"];
    }
  })();
  const getIconByName = (name: IconNames) => {
    switch (name) {
      case IconNames.FINISH:
        return <Finish width={width} height={height} />;
      case IconNames.PENALTY:
        return <Penalty width={width} height={height} />;
      case IconNames.PIT:
        return <Pit width={width} height={height} />;
      case IconNames.RANKING:
        return <Ranking width={width} height={height} />;
      case IconNames.SPEED:
        return <Speed width={width} height={height} />;
      case IconNames.VOTE:
        return <Vote width={width} height={height} />;
      case IconNames.SAFETY_CAR:
        return <SafetyCar width={width} height={height} />;
      case IconNames.V_SAFETY_CAR:
        return <VSafetyCar width={width} height={height} />;
      case IconNames.RED_FLAG:
        return <RedFlag width={width} height={height} />;
      case IconNames.F1_LOGO:
        return <F1Logo width={width} height={height} />;
      case IconNames.HOME:
        return <Home width={width} height={height} />;
      case IconNames.REGISTRATION:
        return <Registration width={width} height={height} />;
      case IconNames.LOGOUT:
        return <Logout width={width} height={height} />;
      case IconNames.PROFILE:
        return <Profile width={width} height={height} />;
      case IconNames.SUCCESS:
        return <Success width={width} height={height} />;
      case IconNames.MVP:
        return <Mvp width={width} height={height} />;
    }
  };
  return <SContainer>{getIconByName(name)}</SContainer>;
};

const SContainer = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
