
import {createAsyncThunk} from '@reduxjs/toolkit';
import {Driver,Maybe} from '../../types';
import {
    getAllDrivers,
} from '../../api';

export const getAllDriversAction = createAsyncThunk<Driver[]>(
    'drivers/getAllDriversAction',
    async () => await getAllDrivers(),
  );