import React, { useEffect, useState } from "react";
import { GenericPage } from "./GenericPage";
import {
  Box,
  Card,
  Divider,
  Heading,
  Loader,
  Table,
  TableToolbar,
  Text,
} from "@wix/design-system";
import { IScore } from "../types";
import { getAllScores } from "../api";

const ScoreboardPage: React.FC = () => {
  const [scores, setScores] = useState<IScore[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const columns = [
    {
      title: "Player",
      render: (row: IScore) => row.username,
    },
    {
      title: "Score",
      render: (row: IScore) => row.score,
    },
  ];
  useEffect(() => {
    (async () => {
      setLoading(true);
      const allScores = await getAllScores();
      setScores(allScores);
    })()
      .catch()
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <GenericPage>
      <Card hideOverflow>
        <Table data={scores} columns={columns}>
          <TableToolbar>
            <TableToolbar.ItemGroup position="start">
              <TableToolbar.Item>
                <TableToolbar.Title>Scoreboard</TableToolbar.Title>
              </TableToolbar.Item>
            </TableToolbar.ItemGroup>
          </TableToolbar>
          <Table.Content titleBarVisible={true} />
        </Table>
        {loading && (
          <Card.Content>
            <Box align="center">
              <Loader />
            </Box>
          </Card.Content>
        )}
      </Card>
    </GenericPage>
  );
};
export default ScoreboardPage;
