import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { Header } from "../components/Header";
import { Icon, IconNames } from "../generics/Icon";
import { Button, ButtonTypes } from "../generics/Button";
import { useUser } from "../hooks/useUser";
import { observer } from "mobx-react-lite";

interface IActionProps {
  name: string;
  onClick: () => void;
}

interface IGenericPageProps {
  title?: string;
  subtitle?: string;
  icon?: IconNames;
  actions?: IActionProps[];
}
const _GenericPage: React.FC<PropsWithChildren<IGenericPageProps>> = ({
  children,
  title,
  subtitle,
  icon,
  actions = [],
}) => {
  const userState = useUser();

  return (
    <StyledPage>
      {userState.isSignedIn && <Header />}
      {(title || subtitle) && (
        <SToolbarContainer>
          <STitleContainer>
            {title && (
              <STitle>
                {title} {icon && <Icon name={icon} />}
              </STitle>
            )}
            {subtitle && <SSubTitle>{subtitle}</SSubTitle>}
          </STitleContainer>
          <SActionsContainer>
            {actions?.map(({ name, onClick }) => (
              <Button
                type={ButtonTypes.SECONDARY}
                text={name}
                onClick={onClick}
              />
            ))}
          </SActionsContainer>
        </SToolbarContainer>
      )}
      {children}
    </StyledPage>
  );
};

export const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7f4f1;
  height: 100%;
  padding: 80px 10px 20px;
  overflow: hidden;
`;

export const SToolbarContainer = styled.div`
  position: relative;
`;

export const STitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;
  align-items: center;
`;

export const STitle = styled.div`
  font-size: 32px;
  line-height: 36px;
  text-transform: uppercase;
  display: flex;
  gap: 16px;
`;

export const SSubTitle = styled.div`
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
`;

export const SActionsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const GenericPage = observer(_GenericPage);
