import { createContext, useContext, useState } from "react";
import { UserState } from "../state";

const UserContext = createContext<UserState | undefined>(undefined);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) throw new Error("useUser must be used within a UserProvider");
  return context;
};

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state] = useState(() => new UserState());

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};
