import React, { createContext, useContext, useState } from "react";
import { RacesState } from "../state";

const RacesContext = createContext<RacesState | undefined>(undefined);

export const useRaces = () => {
  const context = useContext(RacesContext);
  if (!context) throw new Error("useRaces must be used within a RacesProvider");
  return context;
};

export const RacesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state] = useState(() => new RacesState());

  return (
    <RacesContext.Provider value={state}>{children}</RacesContext.Provider>
  );
};
