import React, { useState } from "react";
import { GenericPage } from "./GenericPage";
import {
  AutoComplete,
  Box,
  Button,
  Card,
  FormField,
  Input,
  Loader,
} from "@wix/design-system";
import { observer } from "mobx-react-lite";
import { register } from "../api";
import { UserRole } from "../types";
import { Add } from "@wix/wix-ui-icons-common";

const _RegisterAdmin: React.FC = () => {
  const [username, setUsername] = useState("");
  const [role, setRole] = useState<UserRole>("user");
  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    setIsLoading(true);
    register({ username, role })
      .then(() => {
        setUsername("");
        setRole("user");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <GenericPage>
      <Card hideOverflow>
        <Card.Header
          title="Register"
          suffix={
            <Box verticalAlign="middle">
              <Button
                size="small"
                skin="destructive"
                disabled={!username || !role || isLoading}
                onClick={submit}
                prefixIcon={isLoading ? <Loader size="tiny" /> : <Add />}
              >
                {isLoading ? "" : "Submit"}
              </Button>
            </Box>
          }
        />
        <Card.Divider />
        <Card.Content>
          <Box align="center" direction="vertical" gap={2}>
            <FormField label="Username" required>
              <Input
                value={username}
                onChange={({ target: { value: val } }) => setUsername(val)}
              />
            </FormField>
            <FormField label="Role" required>
              <AutoComplete
                placeholder="role"
                value={role}
                options={[
                  { id: 1, value: "user" },
                  { id: 2, value: "admin" },
                ]}
                onSelect={({ value }) => setRole(value as UserRole)}
              />
            </FormField>
          </Box>
        </Card.Content>
      </Card>
    </GenericPage>
  );
};

export const RegisterAdmin = observer(_RegisterAdmin);
