import { network } from "./"
import { logger } from '../utils';
import { Driver } from '../types';

const BASE = "driver"

export const getDriver = async (raceId: string) => {
  try {
    const { data } = await network.get<Driver>(
      `${BASE}/${raceId}`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return null;
  }
};


export const getAllDrivers = async () => {
  try {
    const { data } = await network.get<Driver[]>(
      `${BASE}`,
    );
    return data;
  } catch (e) {
    logger.error(e); //TODO uncomment
    return [];
  }
};

// export const postDriver = async (race: IRaceBet) => {
//   try {
//     const { data } = await network.post<{ success: boolean }>(
//       `${BASE}`,
//       race,
//     );
//     return data.success
//   } catch (e) {
//     logger.error(e); //TODO uncomment
//     return null;
//   }
// }