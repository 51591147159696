import React, { createContext, useContext, useState, useEffect } from "react";

// Define types for document sizes
interface DocumentSizes {
  width: number;
  height: number;
}

// Define the settings context with the appropriate types
interface SettingsContextType {
  documentSizes: DocumentSizes;
}

// Create the settings context
const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined
);

// Custom hook to access settings context
export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};

// Settings provider component
export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [documentSizes, setDocumentSizes] = useState<DocumentSizes>({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });

  // Update document sizes on window resize
  const handleResize = () => {
    setDocumentSizes({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  };

  // Add event listener for window resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <SettingsContext.Provider value={{ documentSizes }}>
      {children}
    </SettingsContext.Provider>
  );
};
