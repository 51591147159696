import { createSlice } from '@reduxjs/toolkit';
import { IQualifyingBet } from "../../types"
import { getAllQualifyingBetsAction, postQualifyingBetAction, updateQualifyingBetAction } from '../actions';
import { showErrorToast, showSuccessToast } from '../../utils/toast';

interface IQualifyingBetData {
    qualifyingBets: IQualifyingBet[]
    isLoading: boolean;
}

const qualifyingBetsSlice = createSlice({
    name: 'qualifyingBet',
    initialState: {
        qualifyingBets: [],
        isLoading: false
    } as IQualifyingBetData,
    reducers: {



    },
    extraReducers: builder => {
        builder
            // getAllQualifyingBetsAction
            .addCase(getAllQualifyingBetsAction.fulfilled, (state, { payload }) => {
                state.qualifyingBets = payload
                state.isLoading = false;
            })
            .addCase(getAllQualifyingBetsAction.pending, (state, { payload }) => {
                state.isLoading = true;
            })
            // postQualifyingBetAction
            .addCase(postQualifyingBetAction.fulfilled, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = false;
                state.qualifyingBets.push(payload)
                showSuccessToast('Bet saved!')
            })
            .addCase(postQualifyingBetAction.pending, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = true
            })
            .addCase(postQualifyingBetAction.rejected, (state, { payload }) => {
                state.isLoading = false;
                showErrorToast(payload as string)
            })
            // updateQualifyingBetAction
            .addCase(updateQualifyingBetAction.fulfilled, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = false
                state.qualifyingBets = state.qualifyingBets.map(bet => bet._id === payload._id ? { ...payload } : { ...bet })
                showSuccessToast('Bet updated!')
            })
            .addCase(updateQualifyingBetAction.pending, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = true
            })
            .addCase(updateQualifyingBetAction.rejected, (state, { payload }) => {
                // Add user to the state array
                state.isLoading = false
                showErrorToast(payload as string)
            })

    }
});

export default qualifyingBetsSlice.reducer;
