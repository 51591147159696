
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllRacesResults, postRaceResult, updateRaceResult } from '../../api';
import { RaceResult } from '../../types';

export const getAllRaceResultsAction = createAsyncThunk<RaceResult[]>(
    'race/getAllRaceResultsAction',
    async () => await getAllRacesResults() as RaceResult[],
);

export const postRaceResultAction = createAsyncThunk<RaceResult, RaceResult>(
    'race/postRaceResultAction',
    async (raceResult) => {
        const succeed = await postRaceResult(raceResult)
        return raceResult;
    }
)

export const updateRaceResultAction = createAsyncThunk<RaceResult, RaceResult>(
    'race/updateRaceResultAction',
    async (raceResult) => {
        const succeed = await updateRaceResult(raceResult)
        return raceResult;
    }
)