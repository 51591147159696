import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppSelector } from "../hooks";
import { Driver } from "../types";
import DriverCard from "./DriverCard";
import { log } from "console";

interface Props {
  ranks: { driverId: string; rank: number }[];
  length: number;
  selected: (e: any) => void;
}

const DriverRanks: React.FC<Props> = ({ ranks, length, selected }) => {
  const { drivers } = useAppSelector((state) => state.drivers);

  const [driversList, setDriversList] = useState<
    { driverId: string; rank?: number }[]
  >(
    drivers.map((driver) => ({
      driverId: driver._id,
      rank: ranks?.find(
        (rank) => rank.driverId.toString() === driver._id.toString()
      )?.rank,
    }))
  );

  const nextRankAvailable = useMemo(
    () =>
      (function findRankAvailable(rank = 1): number | undefined {
        console.log(rank, length);
        if (rank > length) return;
        return !driversList.find((driver) => driver.rank === rank)
          ? rank
          : findRankAvailable(rank + 1);
      })(),
    [driversList, length]
  );

  useEffect(() => {
    selected({
      completed: !!!nextRankAvailable,
      ranks: driversList.filter((driver) => !!driver.rank),
    });
  }, [nextRankAvailable, driversList]);

  const handleClick = (driverId: string | undefined) => {
    if (!driverId) return;
    const driverIndex = driversList.findIndex(
      (driver) => driver.driverId.toString() === driverId.toString()
    );
    if (driverIndex === -1) return;

    if (!!driversList[driverIndex].rank) {
      // remove rank
      driversList[driverIndex].rank = undefined;
    } else if (nextRankAvailable) {
      // add relevant rank
      driversList[driverIndex].rank = nextRankAvailable;
    }
    setDriversList([...driversList]);
  };

  return (
    <RanksContainer>
      <RanksList>
        {driversList.map((driver) => (
          <DriverCard
            key={driver.driverId}
            _id={driver.driverId}
            selected={!!driver.rank}
            clicked={handleClick}
          />
        ))}
      </RanksList>
      <RanksList style={{ flex: 1 }}>
        {new Array(length).fill("").map((rank, index) => (
          <SRank>{index + 1}</SRank>
        ))}
      </RanksList>
      <RanksList>
        {new Array(length).fill("").map((el, i) => {
          const driverId = driversList.find(
            (driver) => !!driver.rank && driver.rank === i + 1
          )?.driverId as string;
          return (
            <DriverCard key={driverId} _id={driverId} clicked={handleClick} />
          );
        })}
      </RanksList>
    </RanksContainer>
  );
};

export default DriverRanks;

const RanksContainer = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
`;

const RanksList = styled.div`
  background-color: #000;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 4;
`;

const SRank = styled.div`
  height: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
